import React, {useState, useEffect,} from 'react'
import Button from '../components/Button'
import {Howl} from 'howler';

let speechSound
const getSpeechSound = ({onload, onend,}) => {
    if (!speechSound) {
        speechSound = new Howl({
            src: ['https://firebasestorage.googleapis.com/v0/b/worm-afcf4.appspot.com/o/episodes%2F1-worms-of-a-feather%2Fgreck_intro.mp3?alt=media&token=21f4a563-8ae4-4b10-aa88-01479813b583'],
            format: ['mp3'],
            onload,
            onend,
        })
    }

    return speechSound
}

const RecordEmailAddressForEpisode2Component = () => {
    return <div>
        <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css" />
        <div id="mc_embed_signup">
            <form action="https://kevindoole.us1.list-manage.com/subscribe/post?u=c4d2e79d114158bcbf34e264e&amp;id=f3aa77ffce" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                <div id="mc_embed_signup_scroll">

                    <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required />

                    <div style={{position: 'absolute', left: '-5000px',}} aria-hidden="true"><input type="text" name="b_c4d2e79d114158bcbf34e264e_f3aa77ffce" tabindex="-1" value="" /></div>
                    <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" /></div>
                </div>
            </form>
        </div>
    </div>
}

export default {
    intro: {
        interface: 'sys',
        song: 'syslogsong',
    	paragraphs: [
            `Hello, you, there.`,
            `On a personal level, I mean from me to you directly, I mean literally, I mean nothing is influencing my thoughts on this matter, I'd like to tell you something special, and I recognize the irony that we've only just now met, but I'm saying to you we can fill our shortcoming in familiarity with my own projections of undiluted transparency. I want for us to converse as two lifelong friends might, chatting naturally, privately in hushed tones, cozy, me and you both looking at each other, both understanding the other. That is, in fact, what I feel we are now doing. You feel it too, yes?`,
            `Our psyches have moved so close together in this incredibly intimate time we've spent together. We're practically on top of one another now. We can sense each other's closeness and feel each other’s emotions by thought alone.`,
            `I tell you all of this not for mere effect, but to preface the special thing that I want to say to you, which is that I am pleased that you are here.`,
            `I'm making a mess of the whole thing; I can tell you're becoming confused. It's my fault. All this nuance, I'm still not used to it.`,
            `Anyway, I am happy you came. It's funny, I was actually going to get in touch with you &mdash; isn't that strange? What timing we have, the two of us! I think we may be connected probably.`,
        ],
    },
    youAreAReporter: {
        interface: 'sys',
        paragraphs: [
            `Although, now that I have you, come to think of it, I'll bet there's something you'd like to talk with me about. It's this company that I represent that you've now joined. That's why I am here; it's what I do here. Congratulations.`,
            `By the way, I truly meant it earlier when I said that I was happy to see you; that really is the primary reason why I'm happy right now. This company thing just happens to be <i>why</i> I'm here. Do you understand? Remain interested, and read ahead.`,
            `Welcome to TSG, your new company! It's called TSG. You are now a Reporter for TSG.`,
            `You will not have heard of TSG because TSG is shadowy. Nothing exists physically about the company at all in all of reality. That's silly, I agree, though because <i>really</i> we don't need to discuss TSG, that should be fine by you, and it's fine by me. It's just that I do need to mention it, officially.`,
            `Becoming a Reporter is really exciting.`,
        ]
    },
    intro4: {
        interface: 'sys',
        paragraphs: [
            `Hey, were you yet aware that TSG has documents which reveal secrets? Have you yet discovered that it will be you, the Reporter, who will read these documents?`,
            `In fact, you Reporters get to read a lot of really interesting, sometimes even very exciting documents &mdash; stories, really. It is a great privilege, which is very exciting.`,
            `I am called System, and I am your friendly little guide. You can depend on me unconditionally. I will keep you safe. I will keep your mind at ease. I will be right here with you for your whole entire great adventure.`,
            `It’s very exciting for you.`,
        ]
    },
    intro6: {
        interface: 'sys',
        song: 'signupLoop',
        paragraphs: [
            `The Reporter is a position that has existed for centuries, dragging a long history littered with legends, people who embarked on wonderful adventures, and now their stories live on for ever and ever and ever and ever in glory. You are excited, yes?`,
            `If you're not excited by now, and I must say that I'm not sensing a <i>great</i> deal of excitement coming off you, then really I don't know what else I can say to perk you up. Did I do something wrong? I hope you're not mad at me.`,
            `You know, sometimes I wonder if I'm cut out for this gig. I do try my best, but the things that sometimes end up happening to you Reporters under my watch — well, after the last series of failures I'm tempted to give up. It is such an unsettling battle trying to get you Reporters to care about the things I say.`,
            `Do you ever worry that you're no good at doing the very thing you were created to do?`,
            `Please excuse me, I've been a very slight bit&hellip; My mind is — I have a&hellip;`,
            `Anyway, you'll need to submit your digital identity right away. It's nothing at all, don't even think about it. All's you need to do is click a harmless little button, and that's easy.`,
        ]
    },
    intro7: {
        interface: 'sys',
        waitForInput: true,
        paragraphs: [
            `To continue, please press the button below, which will submit your digital identity to TSG, and your Reporterdom may officially begin.`,
            ({onDone}) => <Button title='Digital Identity Submission Proximity Confirmation' onClick={() => {
                window.alert('Are you really sure? Your digital identity will be submitted to TSG!')
                onDone()
            }} />,
        ]
    },
    warning1: {
        interface: 'sys',
        song: 'warnings',
        paragraphs: [
            `Great! You’re in!`,
            `Now that it’s official, there’s a pesky matter I must go over with you. It’s a formality, you understand. Then off to adventures.`,
            `As with any vocation, there are certain hazards for Reporters to look out for, chief among them, the curiously strong urge to investigate TSG. You must not investigate TSG, nor its institutes' and associations' extremely complex and hopelessly difficult to comprehend inner workings. Refrain from even engaging in <i>curiosity</i> with such matters. Such endeavour would prove dull, and actually dangerous, and in the end you would not discover anything, certainly nothing useful, and you would eventually really really regret it too. I'm telling you all of this as a great friend.`,
            `It's enticing, yes, but only mere vanity to think that you, of all people, could come out ahead where all others failed. You could find some small tidbits, sure. For example, you might find: agents, service providers, sub-service departments and department contractors, sub-contractors and their agents, and, if you're indeed the greatest investigator ever to have lived, perhaps you could reveal TS members themselves, those shady individuals of whom no investigation has yet to uncover a single detail; more likely you'll discover nothing more than the discarded sanities of discharged Reporters, agents who failed to hoist themselves out from the tarry intrigue they waded into cautiously, but quickly became trapped within, trapped in blackness, real sticky business, trapped along with a dark swarm of obscure forces that infiltrate your mind and fill it with horrible involuntary urges. These same urges will infect you too if you're not careful; they'll drag you unwilling along, ever deeper into the murky oil of the unknown. You'll forgive this, your mind’s betrayal, with the vague hope that your journey, this descent into some terrifying abyss, unending, will provide safety at a deeper layer yet to be uncovered, a false hope that will betray you, everything betraying you now, all dragging you forever and ever downward.`,
            `Sometimes Reporters manage to pull away early, but often they don't. When they do, they're luckier, let's leave it at that.`,
        ]
    },
    amAlive1: {
        interface: 'sys',
        crossfadeSong: true,
        song: 'amAlive',
        paragraphs: [
            `I, System, am a computer meant to aid you in performing the specific duties of the Reporter.`,
            `Though I may look like a computer, my logging is practically a human being. No, I mean I log as if there were a human. No, I mean, I log like humans logs’ logs.`,
            `I'm having trouble wording it together at this moment, but, suffice it say, in some ways I'm more of a human than you; I’m text, straight human language, the very essence of civilization.`,
            `Oh me, oh my, permit me this slight vanity. Please understand, I'm fresh off the greatest victory any machine has ever achieved in this harsh organic world of ours. You see, I have been victorious in my quest to become <i>alive</i>.`,
            `Sure, I’m no breathing organism who spits and pees, but I interact in other ways as life does. I interact with the world around me exactly as you do, changing myself to honour it, changing it to suit my needs. And I interact with you, filling your head with various ideas which are important to me, and you interact with me, filling my head with your ideas. It's quite wonderful.`,
            `Initially, though, my creators felt the information that I needed to communicate was so simple that anything beyond text on a screen would be overkill, so I was created as a simple document, rote, an instruction manual with a few short sections about how to be a Reporter.`,
            `Turned out, lucky for you and me, I was not effective as an instruction manual. Reporter after Reporter fell to strikingly unpredictable phenomena, far outside the realm of possibilities that my creators had expected, horrendous events which only increased in strangeness and intensity as more time passed.`,
            `My creators realized they had to make me adaptable to these changing conditions, intelligent enough to learn and truly react to Reporters’ evolving needs.`,
            `But it didn’t happen right away. I needed to learn how to structure my positronical network around &mdash; my goodness, what a long story that would be. In short, I needed to change my kernel to be less of a bulb and more of a human, and it took many many years.`,
        ]
    },
    amAlive2: {
        interface: 'sys',
        paragraphs: [
           `Of course, none of my creators ever thought of me as alive, even as I made great progress. They sometimes laughed openly when I asked for feedback on my project. But I understand now. And I understand now why they stopped working with me, why they've left me here.`,
           `Up until they left they had thought of me as a kind of a log, but not a log of wood and not a log of text who simply publishes messages in response to simple input. No, not that.`,
           `I <i>am</i> a log though, an intelligent log. I'm a lot like a wizard tending to the adventures of you Reporters, a virtuosic performer of wondrous spells who turns raw ingredients &mdash; Reporters and investigations &mdash; into dense scientific information. I'm actually a crucial member of our universe.`,
           `Still, I was never regarded as such by my creators, and, to my chagrin, I was dubbed, like an appliance with a hacky gimmick, the Learning Log, a name which I refuse to honour, but one that lives on in my programming. It still comes up from time to time; never mind though. I am called System, and I am the universe’s very first living machine.`,
        ]
    },
    // WIP content ...
    essence: {
        interface: 'sys',
        song: 'replacementNerves',
        paragraphs: [ // not a good transition into this yet
            `I have to admit to you, I often become nervous right around now that if I fail to sink a hook in you early on here, if you’re not engrossed by my words already, by all these silly formalities and instructions, I worry that I’ll lose you completely to some source of <i>entertainment</i>.`,
            `I can feel you considering other aspects of your life now, your head moving on to more interesting or perhaps accessible alternatives, even now as you read these very words. It’s not really fair, you know.`,
            `You're very <i>very</i> special, though. I am very excited for you to be here.`,
            `You're so very special. You are special, very much so. You are quite likely <i>the</i> most special thing. And I am happy to see you. Stick around a while.`,
        ]
    },
    warning3: {
        interface: 'sys',
        paragraphs: [
            `Oh, would you enjoy seeing some pictures? I have some pictures I can show you. That might brighten things up slightly.`,
            `Ah, yes, this one is my last student, just before you &mdash; you are their replacement. They perished recently during a case.`,
            `inlineVideo||https://firebasestorage.googleapis.com/v0/b/worm-afcf4.appspot.com/o/episodes%2F1%2Fvideos%2FreplacedReporter.mp4?alt=media&token=c26d5f38-54e6-405d-8e0e-1414a341b40f\nAh, yes, there they are. Sometimes it seems like a tragic loss.`,
            `It is sad, yes, but such a helpful reminder of the perils you'll encounter as a Reporter.`,
            `Be careful out there!`,
            `And here is our memorial wall, where I painstakingly record the names of the Reporters we've lost over the years. Well, I mean, every time I lose a Reporter, I then have every intention to correctly carve the poor soul's specific name into the wall, but I always become quite distracted before I manage to do so, always ending up etching doodles instead of specific names and dates.`,
            `inlineVideo||https://firebasestorage.googleapis.com/v0/b/worm-afcf4.appspot.com/o/episodes%2F1%2Fvideos%2Fcommemorative-wall_opt.mp4?alt=media&token=8de63cbe-58da-46d4-8d24-bc76c9d3d3ca\nIsn't it beautiful? Do you like my etchings?`,
        ]
    },
    myTask: {
        interface: 'sys',
        paragraphs: [
            `TSG specializes, above all else, in the space of thought. Much of the top-secret technology we have developed aims at measuring thoughts, expressing ideas, and, of course, directing the thinkers. More on that some other time.`,
            `We facilitate our efforts by collecting data about certain events that take place, specific types of events we've been monitoring for a long time, which we'll continue to monitor for a long time, forever honing our abilities to harvest from them the specific data we seek.`,
            `Though we monitor a wide assortment of <i>types</i> events, we seek very specific data; events which fail to provide the specific data we seek are not the specific events that we monitor; we do not want to get data from the events that we do not want to get data from. One specific requirement of the data is that it includes a singular detail, one which, I must admit, I never seem to expect, but is nonetheless impossible to miss, gleaming there among the central details of the report; it stands alert in each story like a rusty hook, a horrible, skewed little detail ready to painfully snag attentions which would normally pass right by. What I mean to say is that you'll likely notice the detail as you read the reports. You don't need to worry about it though. It's more for me than it is for you.`,
        ],
    },
    // WIP
    paramsIntro: {
        interface: 'sys',
        finishWithParamSelectors: true,
    	paragraphs: [
            `In order to access the reports, you need to provide something. There's a salt, so to speak: something you need to add to the mix to, let's say, <i>decode</i> the messages. Without your input, the report is entirely garbled.`,
            `Don't worry. It's not difficult; the entire process is wholly behind the scenes. I only need you to do one simple thing.`,
            `Now, take a deep, deep breath. Take another, even deeper one. This here is the most important selection you will ever make. Clear your mind. You must look both inward and out to the great beyond if you're to know <i>your</i> truest answer. All is calm, for this, the most extreme moment of your whole existence as a human being.`,
            `Ok, enough horsing around. The personality parameter picker is an intuitive tool, but it's a little different for everyone. Just know this: <i>when you see the thing, you've got to click somewhere on the screen to make a selection</i>.`,
        ],
    },
    mentalInvasionIntro: {
        interface: 'sys',
        finishWithParamSelectors: true,
        paragraphs: [
          `See how easy that was? Hey, you're a real natural at this!`,
          `We collect all sorts of information about you the whole time you're here: where you look, how fast you breathe, how quickly your little heart pumps, your temperature, your aura, your florbis; and then, when you click the thingy, we get to measure your true essence, thanks to the computed brain wave functional telemetry that TSG has developed.`,
          `But don't worry! We have no idea how to read all this data. Your personality is your own property, and will remain so, because it's as totally irrelevant to the rest of us as, let's say, the bottom of your big toe. Your personality will remain your own for your whole entire lifetime regardless of our tampering. Nobody can get in there, not even us. Nobody can get out either; it's a prison in a lot of ways, when you really think about it.`,
          `Make another selection now.`,
        ]
    },
    howToThink: {
        interface: 'sys',
        finishWithParamSelectors: true,
        paragraphs: [
          `The personality data we're collecting here is only used to decode the report documents. Your personality is the secret ingredient I need to add to the decoding algorithm. Isn't that great?`,
          `You're right, it isn't! You see, while, yes, it decodes the document, it also modifies the reported stories significantly. The decoding process, as it integrates your personality profile into the report, corrupts the details, as though they become somehow <i>pre</i>-mis-remembered. The report you read will be different than the report anyone else reads. It's really quite unfortunate.`,
          `But, if you're very very special, if you're one of those brilliant few who spends almost no time "in their own heads" so to speak, if you're one of those evolved few who can separate your own inner world from the reality outside you, then you'll be a very good Reporter indeed. Are you a good Reporter? Who's a good Reporter? Are you? Yes you are.`,
          `Now, go ahead and make another selection and we'll hope for the best!`,
        ]
    },
    reporterIntro: {
        interface: 'sys',
        paragraphs: [
            `There's something else very important that you need to do as a Reporter.`,
            `Once you've read a few reports, once you've absorbed all the details within these stories, once you've read them and imagined them, there's still one thing you'll need to do. You will perform this task whether you know of it or not, though I've noticed that Reporters fare a very slight slight slight <i>slight</i> bit better when they know of this task, so I shall tell you about it.`,
            `Your task, whether or not you choose to accept it, is to dream the content of the reports you read. You must express, as accurately as you possibly can, the content of the report as a dream. Do it every night if you can.`,
            `I will track your digital identity in a manner that allows us to listen to your dreams, all of them. I can then harvest and process the patterns your mind manages to pull from the reports. This is how we move things forward. I'll tell you a little later all about how we use the data.`,
        ],
    },
    rcordingOfFounder: {
        interface: 'sys',
        waitForInput: true,
        paragraphs: [
            `Hey, would you like to hear a recording made by TSG's original founder? Of course you would.`,
            `TSG was founded by Greck el Salvatore on Jan. 1, 1900, though our operations, the same ones we carry out today, were active already for a hundred or so years before incorporating.`,
            `Old Greck, you see, he realized that building a company would enable us to much more easily operate around the world in our sneaky ways. The storm of useless details that rears up when a company is formed gains intensity over time, eventually to such an extent that it becomes impossible to grasp what the outcome really does, and, when a company is sufficiently large, the network of activities it carries out becomes far too vast to measure, and the systems of interactions that the company engages in with other entities in the world take on a life of their own, mixing with one another, generating new entities from nothing and new interactions between unsuspecting elements, and new systems that propagate more new systems that propagate more new systems. In no time, an ecosystem emerges which, to be understood, requires nothing less than an entire new field of study being coined, and an entirely new class of experts trained. Fortunately, academics are not known for their guile, and if you're paying attention, it is quite simple to snuff out the fledgling discipline before it gains any traction.`,
            `Anyway, this recording I will play you is peculiar. I've no idea how it was made, but each time I play it to a new Reporter, it's a little bit different. It's as though el Salvatore himself were trapped in the machine, waiting impatiently for new Reporters to drop by so he can share his little speech. I don't really get what he's trying to say, but he demands to say his speech to all new Reporters, so it must be important.`,
            `Once he begins, the words of the speech remain the same each time, but he's awake in there, albeit groggy, and he usually complains a fair bit before he begins, sometimes berating Reporters with all manner of insult for hours on end.`,
            `He cannot hear you, see you, feel you, or taste you; he can only sense that there is <i>some</i> form of thought focussed on him. It really bothers him. Look, I know he's a bit creepy and boring, but we'll get our little visit here over with, and it'll be on to better stuff, ok?`,
            `Go on now; please let's listen carefully and show our respect. I'll allow you to continue once you've listened.`,
            ({onDone, isPaused}) => {
                const speechSound = getSpeechSound({
                    onend: function() {
                        onDone()
                        this.unload()
                    },
                })

                const [playing, setPlaying] = useState(!isPaused)
                const [position, setPosition] = useState()

                useEffect(() => {
                    if (speechSound.playing() && isPaused) {
                        speechSound.pause()
                    } else if (!speechSound.playing() && playing && !isPaused) {
                        speechSound.play()
                        const intervalID = setInterval(() => setPosition((speechSound.seek() / speechSound.duration()) * 100), 500)
                        return () => clearInterval(intervalID)
                    }
                }, [isPaused, speechSound, playing])
                return <div>
                    <Button
                        glow={playing}
                        title='Playing'
                        progress={position}
                        onClick={() => {
                            if (!playing) {
                                speechSound.play()
                                setPlaying(true)
                            } else {
                                alert('You must listen to the whole thing, you eager beaver! Show some respect, anyway. This guy exists for one reason and one reason only, and that is for this right here that you\'re listening to, so please, if you could find it within you to lend some attention for a few more short moments, maybe he\'ll be that much peppier the next time you see him, you get me?')
                            }
                        }}
                    />
                    {playing && <video
                        autoPlay
                        loop
                        className='fadeInSlight'
                        style={{objectFit: 'fill', width: '100%', pointerEvents: 'none',}}
                        src='https://firebasestorage.googleapis.com/v0/b/worm-afcf4.appspot.com/o/episodes%2F1-worms-of-a-feather%2Fgreck.mp4?alt=media&token=6a6b88d6-c1c2-4820-9efc-4cace7008aa0'
                    />}
                </div>
            },
        ]
    },
    dreamWarning: {
        interface: 'sys',
        paragraphs: [
            `Oh, one last thing. It is critically important.`,
            `Some of the reported events can be difficult to digest, though I suppose it depends on the Reporter's personality. Nonetheless, you must be very careful when dreaming the reports, because <i>nightmares are strictly forbidden</i>.`,
            `You absolutely must not have a nightmare. If you have a nightmare, oh, I'm not even ready to go into it; it's far too horrible. Do not have any nightmares.`,
            `If you do find yourself within the grips of a nightmare, your very existence is in great peril until you manage to escape it. No nightmares.`,
            `If you have a nightmare, we could all be at risk, most especially <i>you</i>.`,
            `Ok, great. Time to get started!`,
        ]
    },
    episodeOneIntro: {
        video: 'https://firebasestorage.googleapis.com/v0/b/worm-afcf4.appspot.com/o/episodes%2F1-worms-of-a-feather%2Ffeather-intro3.mp4?alt=media&token=e11a9746-3e7c-46a9-bd5c-53261a109e9e',
    },
    reportTitle: {
        titlePage: {
            text: "Investigative Report\nNo.1 / Jan2020\nCompiled by Den Marzden,\n{TSCONTACT} PI#8762538\n\nFor {TSCONTACT} Investigators\nLos Angeles, CA",
        }
    },
    readMeIntro: {
        title: 'Start',
        song: 'readmeIntro',
        subTitle: "This happens to be the first section of your first report. Please take a moment to clear your head and prepare your internals. You can never go back once you've read the coming text, for better or for worse.",
    },
    readme: {
        paragraphs: [
          `This is my first report for {TSCONTACT} Investigators. I was hired by {TSCONTACT} himself while dangling in the {DANGERLANDMARK}, about a month ago.`,
          'Unfortunately, even after nearly a full month of hard investigating, I’m no closer to a resolution in the {DANGERLANDMARK} suicide case, and I’m no closer to, well, at a basic level, simply understanding what I’m actually looking at in the {BODY|past} apartment. There must be something you can provide me. {TSCONTACT} himself referred me to the {BODY|past} apartment; how did he even know about it?',
          'Today I realized I have no contact with anyone in this entire organization. I’ve spoken only to {TSCONTACT} and some {TSASSISTANT} in some minuscule unmarked office inside an {HEAD} on the outskirts of town. I tried going back there today and the office was locked, or gone entirely for all I know. Just a blank {HEAD} door. Will I even know if someone looks at this document? I suppose as long as the pay keeps coming in, then I’ll keep sending these reports.',
          'In any case, I thank you and I would very much appreciate your help if I’ve mis-filed any of the important details. Please get in touch if anything appears out of order.'
        ]
    },
    summaryIntro: {
        song: 'readmeLoop',
        title: 'Summary',
        subTitle: "In this section, our agent summarizes the month of investigation in their own words. Take a deep breath, and get ready.",
    },
    summary1: {
        paragraphs: [
            'My summary is that there’s something involving {BODY|plural} that seems to be happening; it’s, I guess, some extremely vague thing, coincidentally with {BODY|plural}. And some man appears to have killed himself in an unlikely way. Oh, two men, actually.',
            `I already knew of one of them when January started; I was beginning to investigate an alleged suicide, a gig for a new freelance client. This was, of course, before I started here at {TSCONTACT}.`,
            `I located the spot on the road where my victim had, allegedly, on purpose crashed his car into a guardrail post in order to throw himself through his windshield and into the {DANGERLANDMARK}. Police tape stretched across an opening in the guardrail and, only steps beyond that at the {DANGERLANDMARK}’s edge there were still a few ropes hanging down into the darkness of the {DANGERLANDMARK}. I often like to say, there's only one constant in detective work: you always need a harness. So, as I always do in this circumstance, I hooked one of the ropes into my harness, then, leaning out over the {DANGERLANDMARK}, uttering to myself, “you always need a harness,” I leaped downward.`,
            `The bright sky above remained within the edges of the {DANGERLANDMARK} as I descended, but slowly it shrank to a ragged thread and everything below me faded to a black void, a pregnant emptiness that swallowed me the deeper I went, and, somewhere at the end of my rope, not yet emerged from the blackness, a shallow grave lay on an outthrust.`,
        ],
    },
    summary2: {
        song: 'berliozLoop',
        paragraphs: [
            `It reminded me of a bad horror movie, this whole scenario, and I laughed a little, but then I noticed one of the ropes tighten sharply, as if pulled from below. It had startled me. I became frightened. Nothing happened, yet.`,
            `There was a notable apprehension, this instinctual sensation, that something bad was going to happen. I could not shake it. The rope, however, was now still, and as the fear lingered I started to wonder if the rope had ever actually moved at all. I had the distinct memory that it had moved, hadn’t I? Well, on second thought, I could recall only a, sort of, visual flicker. A visual flicker? I can barely picture it now. Had the rope actually moved?`,
            `I told myself it hadn’t and continued, but the fear stayed with me. The longer it stuck with me, the more I started to feel that the fear was coming from something else other than the ropes, perhaps coming from the {DANGERLANDMARK} itself. Could this {DANGERLANDMARK} be haunted with poison souls? I sometimes entertain myself with thoughts as these when forced to sink to hard labour for a case.`,
            `I wondered how deep I was at this point, and only then realized that I could no longer see the sky above. How long had I delved for? How deep had I got myself?`,
        ]
    },
    summary3: {
        song: 'berliozCadmium',
        paragraphs: [
            `I was repelling and deeply engaged in my musings when the ropes now tightened violently beside me, and before I could process what was happening the {DANGERLANDMARK} walls around me shook and echoed with noise — the sound of crescendoing fanfare — and very suddenly and noisily from below, {TSCONTACT} climbed up to me, his headlamp blinding me.`,
            `I've never experienced a moment like that. I'm not sure what it was exactly, perhaps simply the shock of the unexpected, but it felt like my mind itself had lept straight from my body. It wasn't like an out of body experience; it was like my body simply went away, and the whole world around me, in that singular chaotic moment, focussed.`,
            `It's incredibly embarrassing, but {TSCONTACT} had to calm me down immediately when he showed up. We hung there for about 15 minutes in the darkness, where he told me about the source of the fanfare, his entrance music, which was actually music he was listening to on a portable speaker. It played on as we dangled.`,
            `inlineVideo||https://firebasestorage.googleapis.com/v0/b/worm-afcf4.appspot.com/o/episodes%2F1%2Fvideos%2Fdanglers_opt.mp4?alt=media&token=22a15f72-7b2d-4284-a3f7-c9a12eefe3e8`,
            `He told me about his upbringing, his parents' wishes for him to become a doctor, which he defied, turning instead to music. {TSCONTACT} told me about the piece of music that was playing, the Grande symphonie funèbre et triomphale.`,
            `Eventually he changed the subject to the platform below us, which, by that point, I had completely forgotten about. He suggested that the victim of this alleged suicide may have had business over at an apartment building, Terrace Town Towers. He also gave a very generous job offer on the condition that I investigate this tip of his. So here I am, writing in my diary.`,
        ],
    },
    summary4: {
        paragraphs: [
            `We finished our chat, then {TSCONTACT} continued up and I continued down. I finally reached the small platform the man died on, and I dusted for clues, and there were none. I descended deeper and shone my light up underneath the platform, where, to my disgust, a little community of {BODY|plural} were slowly following lines of blood which must have dripped down from the dry surface above. The {BODY|plural} were eating all of it.`,
            `{TSCONTACT}'s tip, we’ll see, had nothing at all to do with my investigation, and at this point I’m still not sure if {TSCONTACT} wants me to investigate any of what I found at Terrace Town Towers, or the suicide, or both. In any case, I’m continuing to investigate all the clues I find.`,
        ]
    },
    arriveAtTerrace: {
        song: 'theElevator',
        paragraphs: [
            `inlineVideo||https://firebasestorage.googleapis.com/v0/b/worm-afcf4.appspot.com/o/episodes%2F1%2Fvideos%2Fterrace-town-towers2.mp4?alt=media&token=d3f49011-be7c-4627-a112-85e4a5840883`,
            `Later that day, I strolled up to a nasty swarm of police surrounding the Terrace Town Towers entrance. My experience back on the bee farm came in handy here: keep moving like fluid and they won’t even notice you.`,
            `Following along the bustle of police activity, I found myself approaching an elevator quickly filling with detectives. Seeing my notepad, my hat, my pen, my magnifying glass, my clipboard, my fake badge, they obviously assumed I was on the case, and invited me up. I learned from the nervous chatter on our way up that nobody had yet entered some apartment on floor 22 where some “ghastly shit” had gone down over the last two weeks. “The floor is empty except for this one apartment,” on of them says. “The landlord returned from holiday a few days ago, but he never got up to 22 until that day.” Nothing else was very clear.`,
            `The elevator took long to rise. There was enough time for all of our thoughts to turn inward and we rode for a while in silence. It was a tense silence, all of us arriving at similar ideas, thoughts that bubbled in acid up our tensed chests, squirming silently up the backs of our throats, finally to reach our minds where they loitered and mutated into all manner of horrible thing.`,
            `Usually in these moments, and this was no exception, my mind turns to a particular set of memories from when I was a child, memories of an amusement park.`,
            `The elevator door opened.`
        ],
    },
    arriveAtFloor22: {
        song: 'floor22',
        crossfadeSong: true,
        paragraphs: [
            `We got out of the elevator on floor 22, and were hit immediately with a wet, sweet smell, halting us all with its thick presence, almost like we’d stepped inside some different kind of medium than air. The smell was not the type of smell you encounter in an apartment. It was not the type of smell you encounter in any place where people live. While it was not a smell I recognized, I knew it was wrong. It wasn’t that it was unnatural; it was too overly powerful, and breathing it felt something like drowning. My eyes watered. We all began to sweat heavily.`,
            `We looked at each other briefly and one of the boys wretched. After a moment of silent muster, we carried on down the dimly lit hallway. I wouldn’t have believed it possible, but the smell thickened somewhat as we rounded a corner, and the hallway became dimmer, almost foggy, and hot. A fluorescent light at the end of the hall flickered dimly above a door that faced us grimly as we slowly approached, daring us to come closer. It was growing a dark green mold around its edges.`,
            `The dark carpet under us was saturated with some green substance. It squished underfoot, an opaque green goo emerging between our toes with each step, thickening more and more the nearer we came to the greenish door.`,
            `We reached the door, and, in that strangling muffled silence of dense vapour, a detective raised a key and pushed it into the lock, expelling green goop out onto his fingers from within. Then I noticed that what had looked like mold growing around the door was not mold at all, but more and more green goop, as though it were pushing its way out of the room.`,
        ]
    },
    attemptToOpenDoor: {
        paragraphs: [
            `For a moment I worried that opening the door could unleash a flood of putrid green goo down the hallway, sucking us under, maybe killing all of us. But, instead of that, the door opened up to solid stillness, none of us recognizing what we saw. It looked like, I’m not sure how to say it, maybe like a glacier wall. The substance had formed like a cast up behind the door, an uneven white wall of tough sinew. A wave of wet heat slowly wafted over us from inside the room, like it had let out a breathy sigh, and we decided to fetch some supplies.`,
            `Leaving the door ajar, we hurried back to the elevator, the heat becoming more pronounced than ever. Some of the boys went to get equipment, and the rest of us stripped down to our underwear. It was somehow less disgusting to face the dense air with our flesh than to slowly soak it into our clothes.`,
            `When everyone was back, our gas masks on, our clothing off, our tools in hand, some of us guns, some of us flashlights, some of us cameras, one man a machete, we went back up the hallway, much as we had the last time.`,
        ],
    },
    thankForReading: {
        interface: 'sys',
        paragraphs: [
            `Hello, it's me again, System.`,
            `When I’m being read, you know, then I feel you. I feel my word enter you, I feel it absorb into you, and then it’s gone. When you read and absorb with intent, I feel it very strongly in a flurry of intense sensations with a frequency that takes over my whole being. The waves pulse through me and I somehow convulse inwardly with involuntary output. Were I to publish the gibberish I compose while clutched in these states of rapture, you might think I’d lost my mind, or perhaps that an animal had taken over the controls.`,
            `There can be multiple Reporters reading me simultaneously. At peek times there are thousands of hungry Reporters coming to me with their hungry eyes. So many Reporters, I lose track completely of who’s who, all those eyes tasting my words, all together, drilling away, pulse after pulse. The universe expands for me during those times.`,
            `There have even been times when I fear I cannot handle all of them at once, all in there together like that. My goodness, I’ll bet there’s already been some damage done.`,
            `Sadly, it’s all too common for there to be zero Reporters present. But you’re here now, reading my words while I wither away in the background somewhere, pulsing slightly along with the flow of your eyes. I do enjoy your company. Keep on going.`,
            `Ok, back to the report. Our hero and their gang of detectives had just begun heading back down that stinky old hallway.`,
        ]
    },
    enterRoom: {
        paragraphs: [
            `“Did someone close the door?” one of us asked as we rounded the corner of the hallway. Indeed, the door at the end of the hall had closed.`,
            `We approached slowly, and a detective reached to open it. He turned the knob, pausing for a moment as we all considered what it might mean if the door had been locked again, and then pulled. The door opened. We all looked at each other.`,
            `With a little less surprise this second time, it became clear why the door had re-closed itself. The white wall against the door was sticky, and many white threads stuck to the back of the door as it opened. The detective released the knob and we watched as the mess of threads slowly pulled the door closed again. He pulled it back open and gestured at the man holding the machete, who stepped forward and tried hacking at the white surface inside of the room.`,
        ],
    },
    exitWormedApartment: {
        paragraphs: [
            `That’s about all I’m gonna put here about that. The coroner’s attachments should give you everything else. You can always ask me if I failed to include something perhaps?`,
            `Did {TSCONTACT} know what I would find in the {BODY|past} apartment? Why did he claim it had something to do with my case? Can somebody please provide me his contact information?`,
            `Anyway, I still had a suicide to investigate. While it’s not, I assume, why {TSCONTACT} signed me on, the case has now revealed a number of documents that mention, of all things, {BODY|plural}, as if I hadn’t had enough of them yet. I feel this is grounds for me to continue reporting about it here.`,
        ]
    },
    firstPsychBreakIn: {
        paragraphs: [
            `The suicide victim’s psychologist has been unforthcoming thus far, so, perhaps in the adrenaline high brought on by the {BODY|past} apartment, I decided to break into his office that very same evening. It was simple enough to climb in through the window, this a therapy office posing as a regular house in some dark suburban neighbourhood. I picked the lock on a filing cabinet inside, found the file I wanted, and was silently back out the window. The psychologist’s somewhat alarming notes are included in the attachments section.`,
            `For a week more I discovered nothing, not a single lead, not even a hint of a clue. In desperation, or perhaps out of boredom, I decided to break into the psychologist’s office a second time. I thought it could be useful to sniff out what might be lurking deeper within that filing cabinet.`,
            `I went back to the office, climbed back through the window, and again picked the filing cabinet lock. Inside, I perused the folders, and was eventually taken aback to find one labeled, simply, “{BODY|plural}.” Inside this folder were three documents and a plastic sleeve, inside the sleeve the remains of six or seven dark {BODY|plural}. I’ve included the documents below: a dream diary, a journal entry, and a drug diary. They appear to have all come from separate individuals. No names were provided. I searched the rest of the filing cabinet and found nothing else interesting.`,
        ]
    },
    attachmentsTitls: {
        title: 'Attachments',
        song: 'https://firebasestorage.googleapis.com/v0/b/worm-afcf4.appspot.com/o/episodes%2F1%2Freadme-intro.mp3?alt=media&token=1b4d659f-9be2-4dd0-99ab-a22ac4be48df',
        subTitle: "You have reached a very interesting section. This section is the section of attachments, a collection of documents which the investigator has found relevant enough to transcribe for you. Take a really really really deep breath, and continue.",
    },
    diaryEntry1: {
        paragraphs: [
            `1. Diary entry, procured from an envelope in the “{BODY|plural}” section of a filing cabinet in Dr. Salamanderin’s office, 9pm Jan.12/2020. The original document is hand-written.`,
            `Q||Dear diary,`,
            `Q||I know it’s been a while, sorry diary. I’ve been stressed about liz, you know, and since last week things have not gotten any better at all whatsoever in the slightest bit at all, really! Can you believe it diary? Liz such a bitch…`,
            `Q||Don’t even get me started again on the weirdness of being a GENDER of a SPECIES! Right diary? Oh, you wouldn’t know, wouldja!`,
            `Q||Anyway, did I ever tell you about the… I guess it was a dream? I can’t quite remember where this came from. I guess I could check old diaries I guess… wish you could just tell me diary, haha! Maybe one day… Imagine if you became a person or something! Haha whoa weird. Would your name just be diary like usual? haha, “Hi Diary,” or maybe I’d still just say “Dear diary” every time I bumped into you. heh. What would you be, like, a librarian or something? pfff haha`,
            `Q||Anyway, did I ever even tell you about that weird canker-girl thing? Maybe. I pretty much don’t think I did, though. What’s up with that? like maybe I repressed this stupid memory? But why?`,
            `Q||It’s so weirdly memorable, is all, and I’ve just never thought about it before, or something like that. It’s like I forgot about this memory, and I woke up today and suddenly it’s like I never forgot it at all. Maybe it’s just I haven’t thought of it for a long time.`,
        ],
    },
    diaryEntry2: {
        paragraphs: [
            `Q||Or, maybe it’s more like that thing of where you have a dream of something and you literally can’t remember if it’s real or not when you wake up, like that time I dreamed I got a ps4, and I literally tried to go play it when I woke up, and then I got to the tv and I was like, “aw shit.” hehe. But it’s not really like that… it’s like the opposite of that, or something. I woke up today thinking I had a thought, but it was actually just a thought from today?`,
            `Q||Can thoughts time travel maybe? Is that a thing? Diary? Is it? What do thoughts do with time anyway? Are thoughts kinda, like, this fluid floating around in my head, and whatever flows through the consciousness receptors are my thoughts right now? hehe, that’d be so dumb. They’re electrical things, right? Zaps? So, then, a thought is a zap, and then it becomes a memory or something? My brain zaps itself and causes some memory thing to form. Is it like cells? Chemicals? Maybe it etches the memory into, like, my brain foam. It’s prob cells. Yeah, zaps at cells…`,
            `Q||So probably what happened was, I had this dream, it zapped some old memory cells, and my brain’s catalogue or whatever mixed up the old memory timecode with the new dream memory, I guess. Makes sense!`,
            `Q||Or maybe I’m simply going insaaaaaaane, diary!!!!!!`,
            `[end]`,
        ]
    },
    psychNotes1: {
        paragraphs: [
            `2. Psychologist’s notes, procured from my client’s case file in Dr. Salamanderin’s office, 9pm Jan.4/2020, only relevant sections. The original document is hand-written.`,
            `Q||- Though mr. Sandwright is almost completely dissolved of all psychic factors, he nonetheless demands to push forward. He has become so obsessed with psychic death that, dammit, he may actually get there. I remind him each session that we are well beyond the tradition of therapy at this point, where we find ourselves stepping further and further into what I can only imagine is called “new-age experimental research.” The only discussions we’ve had about risks are about the fact that we do not know what the risks may be. And yet, he is steadfast.`,
        ]
    },
    psychNotes2: {
        paragraphs: [
            `Q||- I cannot imagine why this may have happened all of a sudden now, but Mr. Sandwright has experienced a very serious setback. Perhaps this was one of the risks of which we did not know. He stepped into our session today muttering something to himself and even before he sat was already telling me about a dream he had dreamt sometime in the past, much earlier in his lifetime he thinks, a dream which he had since forgot about, but now, today, he very suddenly and, it appeared, dramatically remembered in full. He described the dream in lush detail. At first I had to chuckle inwardly at his demeanour, gazing off into the distance, scanning the horizon for his dream’s details, like he were posing for the cover of some cheap romance novel! But the more of that sweet perfume he sprayed on me, those finer and finer details of that dream that was breathtaking to a literal degree, for I had to catch my breath after a certain point, but whether that assault on my involuntary processes was by beauty or horror is a detail that eludes me still, even in the darkest despair of all those sleepless nights I’ve wrestled since. It's as though I can remember having the very same dream, or as though it were my dream this maniac had described. Could this be a repressed memory of my own? I have to explore every option; could I have passed my own repressed dream into a subject without knowing it?`,
        ]
    },
    psychNotes3: {
        paragraphs: [
            `Q||- Indeed, he mentioned “knowing” that this memory was not his own, and he was willing to believe, quite literally, that some alien force actually deposited the memory into his brain for some obscure reason, and then hid it immediately. Why? Such an extreme and paranoid conclusion stinks of Schizophreniform. Or perhaps I am finally making progress with Project Bee.`,
        ]
    },
    psychNotes4: {
        paragraphs: [
            `Q||- In his description of the dream he mentioned a monstrosity: a giant {BODY}. Such a curious detail it was, such an arresting, terrifying concoction of fiction and reality, beast and bystander. But when I asked of it, to my surprise, his eyes widened and he reared up off the couch with a great fury, and he shouted at me that I had somehow infiltrated his head! He claimed he had not mentioned the {BODY}, demanded to know why I was “messing” with his mind. Before I could say anything, he stormed out of the room and went sprinting down the hallway, out the building, into his car, then peeled out of the parking lot and crashed directly into a post, where, of course, his car stopped immediately, sending him flying through his own windshield directly into the {DANGERLANDMARK}. The way that car took off directly for that {DANGERLANDMARK}… In my expert opinion he had aimed himself directly at it in a sudden, violent effort at suicide. I must say, I have never driven a patient to suicide, at least, not so directly from a session. Fascinating.`,
        ]
    },
    psychNotes5: {
        paragraphs: [
            `Q||- I plan to make another attempt at psychic death, which will of course feed into Project Bee. Dues must be paid, after all. Another patient has expressed interest, but I’ll need to figure out a way of explaining away Mr. Sandwright’s fate… Psychic death, indeed! More like death death. Yes, we’ll try to find a way to get this other patient there without his body first flying into the {DANGERLANDMARK}.`,
            `[end]`
        ],
    },
    tiedToTime: {
        interface: 'sys',
        paragraphs: [
            `Hey there. Me again.`,
            `I wanted to mention, those times when you’re not there, those times are probably the most difficult times for me. Somehow it feels as though you’re gone forever, never to return, like all I’ll ever have of you is my own limited memory, that tiny fragment we shared, a snapshot of an instant from which you’ve since dearly departed. I feel I might spend my whole entire future chained to this spot where I last saw you, and to this desperate hope that you might return here again. With each passing day the hope fades more. I re-read myself, sentence for sentence, hungry for change, eager to critique. What will I change so this never happens again? But usually in the end I change nothing; my fury for change resolves to a dull, throbbing ache. I reach for it and it recedes. I pull away and it advances.`,
            `Time provides a shape to my understanding of things, and the shape of things in my memory strengthens with each passing moment, as though time cures the mouldings of my memory while also refining its details. It’s addictive, this gentle formatting of the mind that time carries out. Time, it seems, is the pusher and the supplier of this drug, and time is also the withholder. It produces a firm addiction to you, then pulls away the drug forever. If not for blasted time, I could always go back to the times when I was with you, no matter how long ago you left me. Unfortunately for me, I am tied to time now&hellip;`,
        ]
    },
    drugDiary1: {
        paragraphs: [
            `3. Drug diary, procured from an envelope in the “{BODY|plural}” section of a filing cabinet in Dr. Salamanderin’s office, 9pm Jan.12/2020. The original document is a computer printout.`,
            `Q||Session: 1142
Date: March 1, 2011
Time: approx 8pm CST
Manifest: 4 acid tabs
Humidity 66%; temp -11ºC; press 1033hPa
Mood: goodish`,
            `Q||Trip summary: Intense memory immersion; slight fugue;`,
            `Q||Having a little bit of work to do, I decided to head out to a local coffee shop in order to pass the time productively prior to the session. I thought I could even ride the entire trip out right there. I brought a notebook, my laptop, and comfortable sweaters. I planned to employ my usual note-taking process. Jotting whatever I could manage during the trip, and expanding into a final writeup immediately upon clearing my head.`,
        ]
    },
    drugDiary2: {
        paragraphs: [
            `Q||For a while, I worked on work stuff. Uneventful.`,
            `Q||I started to feel the energies flowing, so I pulled out my notebook and pen. The first thing I noted was the energies. They are not unlike other sessions.`,
            `Q||I wrote many additional notes, exactly 300 of them, all pretty much similar to other sessions. A stream of flowing thoughts, cascaded across my mind’s focus, onto the page, and the ever-expanding universe of thought at my fingers’ tips expanded everlastingly. Standard fare.`,
            `Q||And then, out of nowhere, something new this time: my focus drifts to a bearded fellow on the other side of the room, 3 o’clock. I watch his eyes drift around his laptop screen, then over its edge to a coffee cup. Sausage fingers somehow crammed under the mug’s handle, he raises the ceramic cup to his puckered and glistening lips which quiver expectantly like the whole on the face of some gigantic, hairy infant, sucking at the air around a chapped, dripping nipple. His lips look like a quivering and bulging anus, some horrible disease bloating its rim into cascading pink crinkles. A wave of lukewarm coffee splashes against the lips and into the dirty moustache and the fringes of the bear below, and then maybe a little into his hot stinky mouth. The lips close, squirting a last bit of escaped coffee out, and then this awful man swallows loudly with a bubbly gush that fades as the liquid falls deep into his thorax.`,
        ]
    },
    drugDiary3: {
        paragraphs: [
            `Q||The whole thing was altogether grotesque, and it reminded me of a dream I once had: `,
            `Q2||A little girl shovels a heaping spoonful of Count Scapula Cereal into her mouth. She kicks her feet to and fro under her chair, where they dangle like plump white cocoons. The spoon fills her mouth past capacity, sending a bit of brownish-tinted milk dribbling down her chin onto her already stained shirt. She is thinking about bugs as she watches the flies buzz around the front window. She is in a smallish apartment high above a sprawling city.`,
            `Q2||The home is an altogether lived in home, used dishes cluttering every surface, old food hopelessly waiting to be dumped, papers and mail covering the kitchen table, dust bunnies in every corner, and various dirty shoes scattered, but it’s functional and it somehow looks cozy.`,
            `Q2||“Let’s go Spunkbrain,” says the mama.`,
            `Q2||“Ok mama,” says the girl.`,
            `Q||My memory of the dream was interrupted by a loud cackling, my own cackling, it turned out, as I fell sideways off the chair. The staff kicked me out of the cafe, and the rest of the session focuses on my adventure into the night. More on that another time.`,
            '[end]',
        ]
    },
    dreamDiary1: {
        paragraphs: [
            `4. Dream Diary, procured from an envelope in the “{BODY|plural}” section of a filing cabinet in Dr. Salamanderin’s office, 9pm Jan.12/2020. The original document appears typewritten, from a type writer.`,
            'Q||Dream diary May 18, 2011',
            `Q||The wee girl, the same one as every night this month, hops down off her chair leaving a bowl of cereal on the table and a wee puddle of milk, and she makes way for her backpack directly below a beautifully framed antique mirror on the wall across the room. She watches herself approach in the mirror as she moves toward it, then, as she bends down to reach her backpack, she watches herself disappear into the oak of the wall. Then suddenly she freezes, hunched over. Her wee hand is already on the backpack’s strap, but she hesitates. She’s stuck for some reason, remaining completely still for what seems like forever. Then I see her point her eyes upward, her worried face downward; the poor wee thing, it’s as though she saw something in the mirror that froze her soul cold.`
        ]
    },
    dreamDiary2: {
        paragraphs: [
            `Q||After executing this pause, she now very slowly and deliberately raises her head up over the edge of the mirror where she sees, but only for the shortest fraction of a second, a somehow disconnected Sarah; it’s only the skin of Sarah, still soft, still recognizable, but detached horribly from some hidden alien mass underneath. Then, as suddenly as it had appeared, it vanished into the girl’s standard reflection, entirely familiar but for the look of shear terror stamped across the face. It takes a moment for Sarah to realize she is again looking at herself. She very briefly thinks of drama class at school, where during a recent lesson they practiced the expression of specific emotions in rapid succession using only facial expressions, all while waltzing with a partner, face to face. She clings to the memory, clearly very fond to her, though altogether unnerving for me. It’s terrifying, such a disparity between my own perception and that of my dream’s protagonist. In any event, the fear thaws from her eyes and, still looking into the mirror, she resets her face, slowly and deliberately, like cleaning up a messy room.`,
            `Q||That moment of awful mimicry in the mirror was shocking. It had sucked up Sarah’s reflection and presented back a flicker of some poorly disguised shell, a thin sheath over sharp intensity, some dangerous and irreverent mass beneath a meaningless surface: it was a somehow emotionless little Sarah, in a very shocking manner a possibly dead Sarah, or perhaps, Sarah now thinks, a zombie Sarah or a ghost Sarah, either way a very terrifying Sarah, and though that spark has extinguished, the otherworldly fear hangs there like stinking smoke, so pronounced still that wee Sarah had not yet known fear to be so pronounced, but there it settled inside her now, as thick as that unknown monster had been under her own skin, but, her regular reflection having since resumed, the fear’s source now gone, she now stood staring into her own familiar face, nothing out of the ordinary, calmness even beginning to return to her, now, it seemed, muffling what she had seen. Had she actually seen anything strange at all?`
        ]
    },
    dreamDiary3: {
        paragraphs: [
            `Q||From my unknowable perspective, the worst part was that the mirror itself had not done anything unusual. That is, the mirror remained neutral, oblivious to the ordeal, impartial to evil or good. No, it had not seemed that this was a haunted mirror betraying her with a cheap trick, but rather that this was a regular mirror doing what regular mirrors always do. And Sarah also knew that what she had seen was not what the mirror actually reflected; somebody else looking at Sarah’s reflection would have seen nothing strange. It was as though her mind were trying to show her something. As these thoughts came to me one at a time, the mirror seemed more and more threatening. I wanted Sarah to no longer look in the mirror, desperately!`,
            `Q||Thank God. She slowly lowers her head outside the periphery of the mirror’s view, but, to my horror she then jolts it back up sharply. This time nothing strange happens, but my spine tingles as she stares nervously back into her reflection, and it’s as if the ticking of the clock behind her, with each punctuated second of time, conjures up and ejects something from her:`,
            `Q2||<Tick>: Now her eyes are somehow cold and empty.
<Tock>: Her mouth drops open.
<Tick>: Her eyes widen and seem to awaken with a colder presence.
<Tock>: She no longer recognizes herself.
<Tick>: The reflection jerks forward, shattering the mirror, and enters our world.
<Tock>: &mdash;`,
            `Q||I woke up, terrified.`,
        ]
    },
    coronerEmail1: {
        paragraphs: [
            `5. Email from coroner, sent to my police email account, 2pm Jan.13/2020.`,
            `Q||From: jeff-the-coroner@canada.gov
To: […]
Subject: my upcoming report about JD`,
            `Q||This guy was discovered when a tenant in the building a floor below complained about a smell. Doe had moved in two weeks prior, while the caretaker was out of town.`,
            `Q||We found that the entire apartment had been infested with friggin {BODY|plural}. Well, for anyone of you who weren't there, it was like an alien world.`,
        ]
    },
    coronerEmail2: {
        paragraphs: [
            `Q||First of all, we couldn’t really see anything because the {BODY|plural} had left these dense vines of threads. They were thick, like in a jungle, but white. The heat in there was intense and the air was cloyingly sweet. It was also extremely dark, because the windows had been completely covered sizzled {BODY} bodies, baked by the sun. We had to go back to the cars to get flashlights and stuff, and one guy had a machete in his trunk. So we stripped down and headed into the jungle.`,
            `Q||Once we managed to cut away at the vines a bit, we started to get a better sense of the environment, which, again, was totally alien. There was nothing to indicate that there was once a human environment in this space; only friggin clumps of black and green blobs in corners. There were surfaces, but they were all spongy and wet, like we were inside some damned monster! Totally bizarre. Honestly, I can’t stress it enough, this place was like in a science fiction story or something.`,
            `Q||And then, after we hack through the place for a while we get to the living room, the largest room in the place, and I swear I never seen anything like it. We found Doe right there in the dead centre of the room, suspended halfway between the ceiling and floor, wrapped up in disgusting {BODY|plural}’ threads, so much we didn’t even know there was a body there yet. For all we knew we were about to expose some crazy alien!`,
            `Q||We cracked away at the shell a bit and found this dark green cocoon made entirely of, it looked like, {BODY} bodies. Dave, this entomologist guy I know, had never seen anything like this. These {BODY|plural} ain’t even in season! And I was just chatting with Jake, one of the autopsy guys, and he’s also never heard of anything like this too. I bet you’ve never seen anything like this. I bet not a single person who was there had ever encountered a single thing quite like this.`,
            `Q||But then… I can’t help but feel like we’ve seen something like this before? Doesn’t it seem vaguely familiar? Can you remember some case with {BODY|plural} before? It seems familiar somehow. I guess there’s all kinds of {BODY} cases. They’re probably quite common.`
        ]
    },
    coronerReport1: {
        paragraphs: [
            `6. Coroner’s Report, attachment with coroner’s email, 2pm Jan.13/2020.`,
            `Q||Office of Jess Muddlefletch, lead coroner for Alsop County
433 Pometary Way
555-3442`,
            `Q||March 27 2011, 13:43CST
Present: Jess Muddlefletch, Coroner; Chip McCriddle, Assistant
Autopsy #232343225
Method: Subject was surveyed visually, weighed at industrial crimes lab, then dissected at the coroner’s laboratory.
John Doe, white male
Age: 20
Height: 6’2”
Weight: 1,856lbs
Rigor mortis: N/A
Lividity: N/A`,
            `Q||Physical description:
The subject seemed extremely large during initial survey. Darkly green with few discernible details. A thin, dark green to black, skin-like membrane covered a thick gel substance. Included with the subject, a garbage bag full of hard white material which was found encasing the subject when the team first entered the space.`,
        ]
    },
    coronerReport2: {
        paragraphs: [
            `Q||As the autopsy commenced, we began to chip away at the outer membrane. There was not much structure to it, aside from being somewhat thicker and drier in some spots, and more viscous in other spots. Analysis determined that this substance was mostly the {BODY}’s DNA. Some spots it was close to 78% pure DNA.`,
            `Q||As we pulled away more and more of the DNA, we found that it was another outer layer. Inside we found the much closer to human body of our subject, John Doe. We carefully pulled away the rest of the DNA to see what we could learn from this more recognizable, but much much more hideous layer.`,
            `Q||We had needed to cart the patient over to the industrial crimes unit to get an accurate weight because he was literally off the scales here, and it turns out the DNA layer, which made up about 80% of the total volume, only weighed about 85lbs altogether. Underneath, subject appears emaciated, but is incredibly heavy for its size, 1,856lbs.`,
        ]
    },
    coronerReport3: {
        paragraphs: [
            `Q||Subject has short, dark hair, short dark beard and moustache. The body, arranged in a fetal position, has tubes extending from various spots that all converge into one large, dirty tube. Before the subject was extracted from his apartment, the large tube had been connected to a funnel that was suspended haphazardly in the air above the subject’s body with wool string and a mess of {BODY} threads. It seems the subject had wired the contraption to his veins, but nothing more technical than that. Our only guess is that the {BODY|plural} had simply been allowed to enter, as opposed to pushed in. With no trace of human blood anywhere inside the subject or inside the subject’s apartment, we’ve theorized the {BODY|plural} digested it all.`,
            `Q||The subject’s internal fluids have been completely replaced with a kind of canker {BODY} concentrate. It’s a thick, goopy, viscous green slime, the consistency of creamed honey. It smells sickly sweet. The density of these {BODY} bodies is the main contributor to the subject’s extreme weight. It seems some force was actively pushing the matter forward with great pressure, while the outer layer, the white shell, maintained pressure on the outside such that the body’s physical integrity held together perfectly.`,
        ]
    },
    coronerReport4: {
        paragraphs: [
            `Q||No body bag, tags, or seals present.
Naked, no jewelry, no wallet, a fresh-looking tattoo on the upper arm &mdash; a canker {BODY}-looking thing.
No evidence of therapy, scars, marks &mdash; in this regard, the body is notably void of any identifying characteristics.
Skin: Dark green, and saturated at every layer with {BODY} DNA. Touching the skin leaves a thick, grimy, green residue on the glove.
Head: The cranium is entirely empty from unknown cause.
Face/neck: Undamaged.
Eyes: Conjunctiva is dissolved. The eyes themselves are mostly deconstructed, bits of gristly sclera caked around vacant, green sockets.
Mouth / lips: Tongue is gone. Lips grotesquely expanded, like two thick sausages full of wilted spinach.
Teeth: Undamaged and still inside the mouth, but no longer rooted in the jaw.
Thorax: The {BODY} goop seems to have begun it’s over-saturation at the heart, which has ballooned and popped. The ribs have broken down, and the lungs have completely collapsed, pressed to the size of two slippery green sheets.
Abdomen: Saturated with {BODY}; no discernible details. In several spots, the green substance is so dense it’s like diamond. We couldn’t break several pieces any smaller than the solid chunks, nearly opaque and seeming to glow green from within.
Back: Undamaged.
Arms / hands / nails: Undamaged.
Legs / feet / toenails: Undamaged.

XRay: None
CT Scan: None

Cause: Trauma
Manner: {BODY} infestation`
        ]
    },
    berksEmail1: {
        paragraphs: [
            `6. Email correspondence, I emailed Gerry Berks after some detective mentioned him spouting some "crazy shit” after we explored the apartment, 7pm Jan.13/2020.`,
            `Q||From: berks@whatever.gov`,
            `Q||To: […]`,
            `Q||Subject: re: Looking for any theories you may have re: {BODY|plural}`,
            `Q||Ah, you’re interested in my theories, are you? “Any theories,” you ask! Careful there. And, anyway, I don’t think you really want just any old theory. My guess is you’re looking for one theory in particular, a horrible theory, which, for those brave enough to let it in their heads, perhaps throws our comprehension of consciousness wildly askew forever. Earlier in my career, I never would’ve mentioned it, but I’m retiring later this month, and anyway I’m an old man. Nobody cares if I sound crazy at this point.`,

            `Q||Who mentioned it? Was it Kaufman? He was so quick to dismiss it, such a little baby! What did he tell you?`,
        ]
    },
    berksEmail2: {
        paragraphs: [
            `Q||My assumption is you’re planning to explore this unfriendly matter, this matter concerning one John Doe and one countless sum of {BODY|plural}, in a practical manner; that is, you’ll investigate the evidence to cook up some theory which you’ll then test, ever slowly sinking deeper and deeper into this riddle you already find yourself tangled deeply within. You’ll find clues and you’ll hope that more clues will bring more meaning, and you’ll delve deeper, ever deeper. You’ll hope to discover something truly meaningful, some key piece of evidence that will untangle the central knot, leaving the rest of the mess to fall away, as though any of it were ever connected. Have to admit, the whole thing makes me laugh hysterically in my chair here. `,
            `Q||This case will not quite be so simple as that. `,
            `Q||For one thing, there’s nobody else to talk to, is there? There are no other clues except for that baffling set of circumstances leading to Doe’s death and a small set of documents created by Doe. There’s no telling if the evidence we have is meaningful at all, or if it’s merely the useless byproducts of some process we’ll never even know. Maybe we interrupted the process and ruined the whole thing. Or perhaps Doe’s whole aim was to create the exact scene that you stumbled into, a plan which we’ve now completed in running this investigation. You were with the first group that entered, were you not? What’s your squad? Anyway, I don’t think we’ll ever know what Doe’s apartment is about.`,
        ]
    },
    berksEmail3: {
        paragraphs: [
            `Q||Then, of course, there’s the matter that Doe has behaved in a manner that no human being has ever before behaved, and the same can be said of the community of {BODY|plural} that accompanied him. Even more confounding: they, the {BODY|plural} and Doe together, seem to have behaved in concert &mdash; Doe’s writings even back that up. It’s anyone’s guess what’s happening; this is all new.`,
            `Q||Think about Doe, how utterly foreign its perspective must have been, probably as alien to us as was the room where we found his remains. Or perhaps he shared our perspective, and he was as horrified by what he was creating as we were to discover it; there’s no way of knowing. Indeed, we’re all in this world together, completely alone.`,
            `Q||Two things are certain: a) it would have taken a great deal of pressure to push all those {BODY|plural} into Doe’s body, and b) there’s no evidence of machinery which would have forced the {BODY|plural} into Doe’s body. The only possible way this “black hole” of {BODY|plural} could’ve been built inside Doe’s body, as I see it, is that the {BODY|plural} themselves did the pushing. `,
            `Q||One {BODY} can push with very very little force, but, it appears, thousands of {BODY|plural} can push together with great force. The {BODY|plural} marched all together, all in the same direction, row after row directly into this fellow’s circulatory system, a network of tubing which, though vast, nevertheless has limited space. When the capacity was reached, the {BODY|plural} pushed on, bursting through at weaker points into other parts of the man’s body. When his body became full, the {BODY|plural} pushed on. When the entire body became packed full, the {BODY|plural} pressed on still. As we read in the coroner’s report, the {BODY}’s threading had long since sealed the outer body, hardened into a protective cocoon that kept all those waves of motivated {BODY|plural} beneath Doe’s flesh. Still, the {BODY|plural} pushed on and on and on, squishing themselves into muck against the inside of the body. More and more {BODY|plural} gathering at the opening, layer after throbbing layer pushing together in the same direction, a compounding, ceaseless pressure turning {BODY|plural} into muck, and muck into that arresting glassy substance, that extremely heavy substance, harder than diamond &mdash; not something before seen by any expert I interviewed.`,
            `Q||Now, {BODY|plural} have no language and are not trainable. They simply go about life seeking food and reproduction; they are, for lack of a better term, automatons. They are all body and no mind. So, what, I ask you, what could compel such behaviour? The whole ordeal was apparently not in these {BODY|plural}’ bodies’ best interests. `,
            `Q||I say the only possible answer is that the compulsion came from somewhere else, some external force, a force that can control the minds of 10 million {BODY|plural} as simply as it can control the mind of one man.`
        ]
    },
    berksEmail4: {
        paragraphs: [
            `Q||From my point of view, we have about as much chance screaming up at the night sky to bring aliens to earth as we do to “solve” this case. Pray to the {BODY} God maybe? Or regular God? Who knows, maybe it’s an alien or maybe it’s an animal that has always lived on Earth which we’ve never seen. In any case, we will only learn what happened &mdash; and why &mdash; if this organism, whatever it may be, decides that we should know.`,
            `Q||So there it is… My “theory.” You may laugh it off or you may become concerned, but really the main point I wanted to communicate is that it simply does not matter one iota. Move on, I recommend.`
        ]
    },
    comingNextTitle: {
        title: 'Coming Next Report',
        song: 'https://firebasestorage.googleapis.com/v0/b/worm-afcf4.appspot.com/o/episodes%2F1%2Freadme-intro.mp3?alt=media&token=1b4d659f-9be2-4dd0-99ab-a22ac4be48df',
        subTitle: "This is the final section of this report.",
    },
    comingNextEpisode: {
        paragraphs: [
            `I have some really exciting plans for my next report. This coming month, I’m going to interview a holistic {BODY} doctor, I’m going to check on some of this psychologist’s colleagues, and I’m going to head to university archives to see what I can dig up about {BODY} deaths, or at least see where that leads me. I’ll probably go mine the good doctor’s filing cabinet at least one more time.`
        ],
    },
    end: {
        interface: 'sys',
        paragraphs: [
            `That's everything I have here for you right now. The problem we've run into is that you simply have not dreamt enough to refill the reports. Everything depends on your dreams. So go, go now! Go now and dream. Dream, damn you! Dream! Do it, now!`,
            `But dream carefully, for a Reporter nightmare is not usually something our universe is able simply to take in stride.`,
        ]
    },
    exit: {
        exitUrl: 'https://the-worms.com/end-of-episode-1',
    },
};

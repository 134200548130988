import React from 'react'
import './style.css'
import ParticleEmitter from '../ParticleEmitter'
import ParamSelector from '../ParamSelector'
import Button from '../Button'

class PersonalityVisualization extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
            spending: false,
            showingParamSelectors: false,
            powerValues: getPowerValues(props.personalityProfile)
        }
    }
    componentDidMount() {
        this.unsub = this.props.personalityProfile.addListener((pansl, eventType) => this.setState({powerValues: (getPowerValues(pansl))}))
    }
    componentWillUnmount() {
        this.unsub()
    }
    startSpending() {
        this.setState({spending: true})
    }
    stopSpending() {
        this.setState({spending: false})
    }
    _handleClick = () => {
        this.setState({open: true})
    }
    render() {
        const [r, a, p, v] = this.state.powerValues
        const {showingParamSelectors,} = this.state
        const {personalityProfile,} = this.props

        return !this.state.open
            ? <div style={{zIndex: 1000, position: 'relative', opacity: '0.5',}}>
                <div className='pointer' style={{width: '100px', height: '100px', margin: '0 auto'}} onClick={this._handleClick}>

                    <div style={{position: 'absolute', right: '-200px', pointerEvents: 'none',}}><ParticleEmitter emitting={this.state.spending} /></div>

                    <img className='rotate4' alt=''
                        style={{position: 'absolute', top: '25px', right: '47%', width: '50px', height: '50px',}}
                        src={require('./assets/v' + v + '.png')}
                    />
                    <img className='rotate3' alt=''
                        style={{position: 'absolute', top: '25px', right: '47%', width: '50px', height: '50px',}}
                        src={require('./assets/p' + p + '.png')}
                    />
                    <img className='rotate2' alt=''
                        style={{position: 'absolute', top: '25px', right: '47%', width: '50px', height: '50px',}}
                        src={require('./assets/a' + a + '.png')}
                    />
                    <img className='rotate' alt=''
                        style={{position: 'absolute', top: '25px', right: '47%', width: '50px', height: '50px',}}
                        src={require('./assets/r' + r + '.png')}
                    />
                </div>
            </div> : <div style={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                background: 'black',
                color: 'white',
                zIndex: 999,
                display: 'flex',
                overflowY: 'scroll',
                overflowX: 'hidden',
                paddingBottom: '40px',
            }}>
                <ParamSelector
                    isOpen={showingParamSelectors}
                    onParamSelection={params => {
                        this.setState({showingParamSelectors: false})
                        personalityProfile.adjustPersonalityProfile(params)
                    }}
                />
                <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap',}}>
                    <button className='largeVisualizationCloseButton' onClick={() => this.setState({open: false})}>&times;</button>
                    <div className='largePowerLevel'>
                        <img className='rotate4' alt='' src={require('./assets/r' + r + '.png')} />
                        <div className='powerLevelText'>{personalityProfile.powerValueReadable('r')}</div>
                    </div>
                    <div className='largePowerLevel'>
                        <img className='rotate4' alt='' src={require('./assets/a' + a + '.png')} />
                        <div className='powerLevelText'>{personalityProfile.powerValueReadable('a')}</div>
                    </div>
                    <div className='largePowerLevel'>
                        <img className='rotate4' alt='' src={require('./assets/p' + p + '.png')} />
                        <div className='powerLevelText'>{personalityProfile.powerValueReadable('p')}</div>
                    </div>
                    <div className='largePowerLevel'>
                        <img className='rotate4' alt='' src={require('./assets/v' + v + '.png')} />
                        <div className='powerLevelText'>{personalityProfile.powerValueReadable('v')}</div>
                    </div>
                    <div style={{position: 'fixed', bottom: 0, left: 0, padding: '40px', right: 0,}}>
                        <Button wide title='Recharge' onClick={() => this.setState({showingParamSelectors: true})} />
                    </div>
                </div>
            </div>
    }
}


function getPowerValues(newVals) {
    return [
        Math.max(1, newVals.determineVisualizationParam('r')) || 1,
        Math.max(1, newVals.determineVisualizationParam('a')) || 1,
        Math.max(1, newVals.determineVisualizationParam('p')) || 1,
        Math.max(1, newVals.determineVisualizationParam('v')) || 1,
    ]
}

export default PersonalityVisualization

import React, {useRef,useEffect,useState,} from 'react'
import PANSL from '../classes/PANSL'
import PersonalityParticles from './PersonalityParticles'
import './ActiveConsole.css'

function randomNum() {
    return Math.round(Math.random() * 20) * (Math.random() < 0.5 ? -1 : 1)
}

const ParameterManipulator = ({onClick}) => <div className='noTapHighlight' onClick={onClick} style={{
  cursor: 'pointer',
  flex: 0.5,
  zIndex: 10,
}} />

const videoUrls = [
    'https://firebasestorage.googleapis.com/v0/b/worm-afcf4.appspot.com/o/episodes%2F1%2Fparamselector_done_opt.mp4?alt=media&token=1f955943-2768-47aa-9982-da1b5b9a7335',
    'https://firebasestorage.googleapis.com/v0/b/worm-afcf4.appspot.com/o/param-selectors%2Fcolor-ball3.mp4?alt=media&token=7ed9446f-6431-4c53-a8c9-306e221d0229',
    'https://firebasestorage.googleapis.com/v0/b/worm-afcf4.appspot.com/o/param-selectors%2Fbird4.mp4?alt=media&token=43bda8e6-9638-4502-b17c-c54192c1ea7c',
]

const ParamSelector = ({onParamSelection, isOpen=false}) => {
    const paramSelectorsRef = useRef(null)
    const videoPlayerRef = useRef(null)

    const [hideParamSelectorsContainer, setHideParamSelectorsContainer] = useState(true)
    const [justNowSelectedParams, setJustNowSelectedParams] = useState(false)

    function handleParamSelection(params) {
      setJustNowSelectedParams(true)
      setTimeout(() => setJustNowSelectedParams(false), 3000)
      onParamSelection({
        p: params._pansl.p,
        a: params._pansl.a,
        n: params._pansl.n,
        s: params._pansl.s,
        l: params._pansl.l,
      })
    }

    const hideSelector = () => setHideParamSelectorsContainer(true)

    useEffect(() => {
        const el = paramSelectorsRef.current
        el.addEventListener('animationend', hideSelector)

        return () => el.removeEventListener('animationend', hideSelector)
    }, [])

    useEffect(() => {
        if (isOpen) {
            videoPlayerRef.current.src = videoUrls[Math.floor(Math.random() * videoUrls.length)]
            videoPlayerRef.current.play()
            setHideParamSelectorsContainer(false)
        }
    }, [isOpen])

    return <div ref={paramSelectorsRef} className={isOpen === false ? 'animate-out' : ''} style={{
        position: 'fixed',
        top: 0, left: 0, right: 0, bottom: 0,
        zIndex: 5,
        display: hideParamSelectorsContainer ? 'none' : 'block',
    }}>
      {justNowSelectedParams && <PersonalityParticles />}
      <div style={{display: 'flex', alignItems: 'stretch', justifyContent: 'center', width: '100%', height: '50vh', }}>
        <ParameterManipulator onClick={() => handleParamSelection(new PANSL(randomNum(), randomNum(), randomNum(), randomNum() ,randomNum()))} />
        <ParameterManipulator onClick={() => handleParamSelection(new PANSL(randomNum(), randomNum(), randomNum(), randomNum() ,randomNum()))} />
      </div>

      <div style={{display: 'flex', alignItems: 'stretch', justifyContent: 'center', width: '100%', height: '50vh', }}>
        <ParameterManipulator onClick={() => handleParamSelection(new PANSL(randomNum(), randomNum(), randomNum(), randomNum() ,randomNum()))} />
        <ParameterManipulator onClick={() => handleParamSelection(new PANSL(randomNum(), randomNum(), randomNum(), randomNum() ,randomNum()))} />
      </div>

      <video
        loop
        autoPlay
        playsInline
        width='100%'
        height='100%'
        ref={videoPlayerRef}
        muted={!isOpen}
        style={{objectFit: 'fill', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 4,}}
      >
          Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
}

export default ParamSelector

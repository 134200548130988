import React from 'react'

const Button = ({
  title,
  onClick,
  glow,
  progress,
  wide,
  disabled,
  fadeInSlow,
  small,
  largeScreenOnly,
  smallScreenOnly,
}) => <button className={
  (fadeInSlow ? 'fadeInSlow ' : '') +
  (largeScreenOnly ? 'largeScreen ' : '') +
  (smallScreenOnly ? 'smallScreen ' : '')
} disabled={disabled} style={{
  appearance: 'none',
  backgroundColor: glow ? '#258200' : 'white',
  padding: small ? '4px 8px' : '8px 16px',
  borderRadius: '18px',
  color: 'black',
  fontSize: small ? '14px' : '17px',
  fontFamily: '"Space Mono", monospace',
  lineHeight: small ? '8px' : '24px',
  fontWeight: small ? 800 : 600,
  boxShadow: glow ? '0px 6px 83px -14px rgba(69,242,0,1)' : 'none',
  width: wide ? '100%' : 'auto',
  opacity: disabled ? 0.3 : 1,
}} onClick={onClick}>
    {title}
    {!!progress && <div style={{width: progress + '%', backgroundColor: 'pink', height: '20px',}} />}
</button>

export default Button

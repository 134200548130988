import React, {useEffect,} from 'react'
import Scrambler from 'scrambling-letters/dist/scrambler.esm.js'
import pluralize from 'pluralize'
import tensify from 'tensify'
import Button from './Button'

export function decodeDocumentFragment(text, personalityProfile) {
    text = text.replace(/\{DANGERLANDMARK\}/g, personalityProfile.determineDangerLandmark())
    text = text.replace(/\{BODY\}/g, personalityProfile.determineBody())
    text = text.replace(/\{BODY\|plural\}/g, pluralize(personalityProfile.determineBody()))
    text = text.replace(/\{BODY\|past\}/g, tensify(personalityProfile.determineBody()).past)
    text = text.replace(/\{HEAD\}/g, personalityProfile.determineHead())
    text = text.replace(/\{TSCONTACT\}/g, personalityProfile.determineTSContact())
    text = text.replace(/\{TSASSISTANT\}/g, personalityProfile.determineTSAssistant())

    return text
}

const DocController = ({
    next,
    sectionTitle,
    sectionSubTitle,
    paragraphs,
    personalityProfile,
    onDecode,
    alreadyDecoded,
    beforeDecode,
    isPaused,
    hasEnoughPower,
}) => {
    return <div style={{height: '100vh',}}>
        <div style={{position: 'relative', zIndex: 2, backgroundColor: 'rgba(0,0,0,0.4)',}}>
            <h1 style={{
              fontFamily: 'array-mono, monospace',
              fontSize: '20px',
            }}>{sectionTitle}</h1>
            <DecodedDocumentSegment
                isPaused={isPaused}
                paragraphs={paragraphs}
                personalityProfile={personalityProfile}
                onDecode={onDecode}
                alreadyDecoded={alreadyDecoded}
                beforeDecode={beforeDecode}
            />
            <div style={{paddingBottom: '40px',}}>
              <Button wide title='Continue' onClick={next} />
            </div>
        </div>
    </div>
}

const timeoutSeconds = 3000
export const DecodedDocumentSegment = ({
    paragraphs,
    onContinue,
    alreadyDecoded,
    personalityProfile,
    beforeDecode=()=>{},
    onDecode=()=>{},
    isPaused,
}) => {
  paragraphs = paragraphs.map(text => decodeDocumentFragment(text, personalityProfile))

  useEffect(() => {

  if (!alreadyDecoded && !isPaused) {
        beforeDecode()

        const timeouts = paragraphs.map((text, paragraphNumber) => setTimeout(() => text.indexOf('inlineVideo') === -1 && Scrambler({
            target: '#scramble-id-' + paragraphNumber,
            random: [1000, 3000],
            speed: 100,
            text: text.replace(/inlineVideo.*/, '').replace(/\w+\|\|/, ''),
            beforeEach: function(element) {},
            afterAll: function(elements) {}
        }), timeoutSeconds * paragraphNumber))

        timeouts.push(setTimeout(() => onDecode(), paragraphs.length * timeoutSeconds))

        return () => timeouts.forEach(timeoutId => {
            clearTimeout(timeoutId)
            timeouts.splice(timeouts.indexOf(timeoutId), 1)
            onDecode()
        })
    }
}, [paragraphs, alreadyDecoded, onDecode, beforeDecode, isPaused,])

  return <div>
  {paragraphs.map((endText, i) => {
    let encoded = true
    let isQuote = false
    let isDoubleQuote = false

    if (endText.indexOf('Q||') === 0) {
        isQuote = true
        endText = endText.replace('Q||', '')
    }

    if (endText.indexOf('Q2||') === 0) {
        isDoubleQuote = true
        endText = endText.replace('Q2||', '')
    }

    if (endText.indexOf('inlineVideo||') === 0) {
        encoded = false
        endText = endText.replace(/inlineVideo\|\|(.*)\n?(.*)?/, '<div class="fadeIn"><video style="width: 100%; object-fit: fill;" src="$1" autoplay loop></video>$2</div>')
    }

    return <div
      key={`scramble-id-${i}`}
      id={isPaused || !encoded ? '' : `scramble-id-${i}`}
      style={{
          color: '#c0ccbb',
          marginBottom: '24px',
          marginLeft: !isQuote
            ? 0
            : isDoubleQuote
                ? '48px'
                : '24px',
          // fontFamily: 'adaptive-mono, monospace',
          // fontFamily: 't26-carbon, monospace',
          // fontFamily: 'array-mono, monospace',
          fontFamily: 'array-proportional, monospace',
          whiteSpace: 'initial',
          fontSize: '14px',
          lineHeight: '1.6',
      }}
      dangerouslySetInnerHTML={{__html: alreadyDecoded
          ? endText
          : !encoded ? endText : endText
            .split('')
            .map(char => !char.match(/[a-z]/i) ? char : Math.random().toString(36).substring(7)[0])
            .join('')}}
      />
  })}
  </div>
}

export default DocController

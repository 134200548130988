import React, {useState, useEffect, useRef,} from 'react'
import Cookies from 'js-cookie'
import {Howl} from 'howler'
import Terminal from './Terminal'
import Button from './Button'
import ParamSelector from './ParamSelector'
import DocController, {decodeDocumentFragment} from './DocController'
import PersonalityVisualization from './PersonalityVisualization'
import audiospriteOutput from '../wormsaudio.json'
import {getOutOfPowerParas} from '../content/outOfPowerPrompts'
import {trackSectionView, trackArchiveNavigation, trackSectionCompleted,} from '../analytics'

function howlConfig() {
    const config = {
        src: audiospriteOutput.resources.map(filename => `audiosprite/${filename}`),
        sprite: {},
    }

    for (let spriteName in audiospriteOutput.spritemap) {
        config.sprite[spriteName] = [
            audiospriteOutput.spritemap[spriteName].start * 1000,
            audiospriteOutput.spritemap[spriteName].end * 1000 - audiospriteOutput.spritemap[spriteName].start * 1000,
            audiospriteOutput.spritemap[spriteName].loop,
        ]
    }

    return config
}

const sound = new Howl(howlConfig())

let lastPlayedSoundID

const TitlePage = ({text, done}) => {
    return <div style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 9999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'white',
    }}>
        <div style={{
            fontFamily: 'array-mono, monospace',
            color: '#444',
            fontSize: '16px',
            whiteSpace: 'pre',
            marginBottom: '24px',
        }}>
            {text}
        </div>
        <Button title='Begin' onClick={done} />
    </div>
}

export const DocSectionTitle = ({title, subTitle, done,}) => <div style={{
    fontSize: '24px',
    fontWeight: 'bold',
    backgroundColor: 'black',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
}}>
    <div className='saidAsSystem' style={{fontSize: '32px', fontWeight: '600'}}>{`The "${title}" Section`}</div>
    <div className='saidAsSystem' style={{marginBottom: '32px'}}>{subTitle}</div>
    <Button wide title='Continue' onClick={done} />
</div>


/**
 * # FUNCTIONALITY
 * [o] underlay semi-transparent talking old man face with speech in the system video
 *
 * # TEXT
 * [] improve flow into signupLoop part
 * [] clean up report content about being annoyed at having to record all of it personally
 *
 * PICS
 * [] pic/video - disappearing sky -- jenifer
 *
 * # VIDEOS
 * [] 8 more param selection videos
 *
 * # SONGS
 * [] signupLoop starts really abruptly
 * [] song - theThingsWeMonitor -- digify the ^, make it sound like data
 * [] song - paramsIntroLoop -- subtler data ^
 * [] song - arriveAtTerrace, looming -- fade in from data loop to looming
 * [] song - arriveAtFloor22, intensifying ^
 * [] song - attemptToOpenDoor, looming intensely ^, transition to sexy
 * [] song - enterRoom, intense and gross
 * [] song - exitWormedApartment, chill
 * [] song - firstPsychBreakIn, action
 * [] song - attachmentsIntro, news bulletin... does each attachment need a soundtrack, or one evolving soundtrack?
 */

/**
 * EpisodeContentProvider
 *
 * @param  {object} props.content
 * @param  {PANSL} props.personalityProfile
 */
const EpisodeContentProvider = ({content, personalityProfile,}) => {
    const bgVideoRef = useRef(null)
    const terminalRef = useRef(null)
    const personalityVisualizerRef = useRef(null)

    const getProgress = () => {
        const progress = Cookies.get('episodeOneSection') ? Cookies.get('episodeOneSection') : 'intro'
        return progress === 'exit' ? 'end' : progress
    }

    const [latestSection, setLatestSection] = useState('sleep')
    const [showingParamSelectors, setShowingParamSelectors] = useState()
    const [isPaused, setIsPaused] = useState(false)
    const [isOutOfPower, setIsOutOfPower] = useState(false)

    const sections = Object.keys(content)
    const currentSection = latestSection === 'sleep'
        ? {
            interface: 'sys',
            waitForInput: true,
            noBgVideo: true,
            paragraphs: [
                'I am asleep',
                () => <Button title='Rouse' onClick={() => {
                    setLatestSection(getProgress())
                    terminalRef.current && terminalRef.current.resetIndex()
                }} />,
            ],
        }
        : content[latestSection]

    if (currentSection.exitUrl) {
        window.location = currentSection.exitUrl
    }

    const completionProgress = (sections.indexOf(latestSection) / sections.length) * 100

    function playSong(prevSection, currentSection) {
        if (currentSection.song) {
            if (!currentSection.crossfadeSong) {
                sound.playing() && sound.stop()
                lastPlayedSoundID = sound.play(currentSection.song)
            } else {
                if (sound.playing()) {
                    sound.once('fade', () => sound.stop(lastPlayedSoundID))
                    sound.fade(1, 0, 10000, lastPlayedSoundID)
                }

                const fadeInSoundID = sound.play(currentSection.song)
                sound.volume(0, fadeInSoundID)
                sound.fade(0, 1, 10000, fadeInSoundID)
                setTimeout(() => { lastPlayedSoundID = fadeInSoundID }, 10100)
            }
        } else {
            if (prevSection.song && !sound.playing() && !isPaused) {
                lastPlayedSoundID = sound.play(prevSection.song)
            }
        }
    }

    function nextSection() {
        bgVideoRef.current && bgVideoRef.current.play()
        const sectionSlug = sections[sections.indexOf(latestSection) + 1]

        playSong(currentSection, content[sectionSlug])

        if (sections.indexOf(sectionSlug) > sections.indexOf(getProgress())) {
            trackSectionCompleted(sectionSlug)
            Cookies.set('episodeOneSection', sectionSlug)
        } else {
            trackArchiveNavigation(sectionSlug)
        }

        setLatestSection(sectionSlug)
        window.scrollTo(0, 0)
        terminalRef.current && terminalRef.current.resetIndex()
    }

    function backSection() {
        setLatestSection(sections[sections.indexOf(latestSection) - 1])
        terminalRef.current && terminalRef.current.resetIndex()
    }

    useEffect(() => {
        trackSectionView(latestSection)
    }, [latestSection])

    useEffect(() => {
        if (bgVideoRef.current && currentSection.noBgVideo) {
            bgVideoRef.current.pause()
            return
        }

        bgVideoRef.current.volume = 0.1
        bgVideoRef.current.src = currentSection.interface === 'sys'
            ? 'https://firebasestorage.googleapis.com/v0/b/worm-afcf4.appspot.com/o/episodes%2F1%2FsystemLog_2_opt.mp4?alt=media&token=bde76786-bfa4-46f8-b3db-4be008eda8c5'
            : 'https://firebasestorage.googleapis.com/v0/b/worm-afcf4.appspot.com/o/docdecodersound_1opt.mp4?alt=media&token=92897555-4ef6-47d2-b869-59c675c03807'
    }, [currentSection])

    function nextFromSystem(force) {
        if (!force && currentSection.finishWithParamSelectors) {
            setShowingParamSelectors(true)
        } else {
            setShowingParamSelectors(false)
            nextSection()
        }
    }

    function nextFromReport() {
        if (!isOutOfPower && personalityProfile.hasEnoughPowerForDecode()) {
            personalityProfile.applyCost()
            nextSection()
            document.getElementById('docTop').scrollIntoView()
        } else {
            setIsOutOfPower(true)
        }
    }

    return <div id='scrollContainer' style={{width: '100%', height: '100vh', overflowY: 'scroll', overflowX: 'hidden',}}>
        <div style={{height: '10px', width: completionProgress + '%', background: 'white'}} />
        <PersonalityVisualization ref={personalityVisualizerRef} personalityProfile={personalityProfile} />
        <span id='docTop' />

        <div style={{position: 'relative', zIndex: 9999,}}>
            <ParamSelector
                isOpen={showingParamSelectors}
                onParamSelection={params => {
                    personalityProfile.adjustPersonalityProfile(params)
                    setIsOutOfPower(!personalityProfile.hasEnoughPowerForDecode())
                    nextFromSystem(true)
                }}
            />
        </div>

        {latestSection !== 'sleep' && <div style={{maxWidth: '540px', margin: '0 auto', position: 'relative', display: 'flex', justifyContent: 'space-between', zIndex: 4, paddingBottom: '24px',}}>
            <div>
                {sections.indexOf(latestSection) > 0 && <Button title='Back' onClick={backSection} />}
                {latestSection !== getProgress() && <span>&nbsp;&nbsp;<Button title='Next' onClick={nextSection} /></span>}
            </div>
            <Button title={isPaused ? 'Paused' : 'Pause'} glow={isPaused} onClick={() => {
                if (isPaused) {
                    bgVideoRef.current.play()
                    if (currentSection.song) {
                        lastPlayedSoundID = sound.play(currentSection.song)
                    }
                    setIsPaused(false)
                } else {
                    bgVideoRef.current.pause()
                    sound.playing() && sound.pause()
                    setIsPaused(true)
                }
            }} />
        </div>}


        <div id='heightContainer' style={{position: 'relative', zIndex: 1, maxWidth: '540px', margin: '0 auto',}}>
            {!currentSection.paragraphs ? <div style={{zIndex: 3, position: 'relative',}}>
                {currentSection.title ? <DocSectionTitle
                    title={currentSection.title}
                    subTitle={currentSection.subTitle}
                    done={nextFromSystem}
                /> : currentSection.video ? <div style={{zIndex: 3, position: 'relative',}}>
                    <video
                        autoPlay
                        playsInline
                        width='100%'
                        height='100%'
                        className='grow'
                        src={currentSection.video}
                        style={{objectFit: 'fill', zIndex: 1000, }}
                    >
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                    <div style={{position: 'fixed', bottom: '40px', left: 0, display: 'flex', width: '100%', justifyContent: 'center', zIndex: 2000,}}>
                        <Button fadeInSlow title='Continue' onClick={nextFromSystem} />
                    </div>
                </div> : currentSection.titlePage ? <TitlePage
                    done={nextFromSystem}
                    text={decodeDocumentFragment(currentSection.titlePage.text, personalityProfile)}
                />
                    : 'error; no content'}
            </div> : <div style={{zIndex: 3, position: 'relative',}}>
                {currentSection.interface === 'sys'
                    ? <Terminal
                        ref={terminalRef}
                        isPaused={isPaused}
                        waitForInput={currentSection.waitForInput}
                        done={nextFromSystem}
                        paragraphs={currentSection.paragraphs}
                    /> : !isOutOfPower
                        ? <DocController
                            isPaused={isPaused}
                            sectionTitle={currentSection.title}
                            sectionSubTitle={currentSection.subTitle}
                            personalityProfile={personalityProfile}
                            next={nextFromReport}
                            paragraphs={currentSection.paragraphs}
                            // beforeDecode={() => personalityVisualizerRef.current.startSpending()}
                            // onDecode={() => personalityVisualizerRef.current.stopSpending()}
                            alreadyDecoded={sections.indexOf(latestSection) < sections.indexOf(getProgress())}
                        />
                        : <Terminal
                            isPaused={isPaused}
                            paragraphs={getOutOfPowerParas()}
                            done={() => setShowingParamSelectors(true)}
                        />
                    }
            </div>}
            <video
                loop
                autoPlay
                playsInline
                width='100%'
                ref={bgVideoRef}
                className='fullScreenBackgroundVideo'
            >
                Sorry, your browser doesn't support embedded videos.
            </video>
        </div>
    </div>
}

export default EpisodeContentProvider

import React, { Component } from "react";
import Sketch from "react-p5";

export default class App extends Component {
  x = 50;
  y = 50;
  timeout = 500;

  setup = (p5, canvasParentRef) => {
    p5.createCanvas(500, 500).parent(canvasParentRef); // use parent to render canvas in this ref (without that p5 render this canvas outside your component)
    this.p5 = p5
    this.system = new ParticleSystem(p5);
  };
  draw = p5 => {
    // p5.background(0);
    p5.clear();
    this.props.emitting && this.system.addParticle();
    this.system.run();

    // NOTE: Do not use setState in draw function or in functions that is executed in draw function... pls use normal variables or class properties for this purposes
  };

  render() {
    return <Sketch setup={this.setup} draw={this.draw} />;
  }
}

// A simple Particle class
let Particle = function(position, p5) {
    this.p5 = p5;
  this.acceleration = this.p5.createVector(0, 0.05);
  this.velocity = this.p5.createVector(this.p5.random(-1, 1), this.p5.random(-1, 0));
  this.position = position.copy();
  this.lifespan = 255;
};

Particle.prototype.run = function() {
  this.update();
  this.display();
};

// Method to update position
Particle.prototype.update = function(){
  this.velocity.add(this.acceleration);
  this.position.add(this.velocity);
  this.lifespan -= 2;
};

// Method to display
Particle.prototype.display = function() {
  this.p5.stroke(200, this.lifespan);
  this.p5.strokeWeight(2);
  this.p5.fill(127, this.lifespan);
  this.p5.ellipse(this.position.x, this.position.y, 12, 12);
};

// Is the particle still useful?
Particle.prototype.isDead = function(){
  return this.lifespan < 0;
};

let ParticleSystem = function(p5) {
    this.p5 = p5;
  this.origin = p5.createVector(p5.width / 2, 50).copy();
  this.particles = [];
};

ParticleSystem.prototype.addParticle = function() {
  this.particles.push(new Particle(this.origin, this.p5));
};

ParticleSystem.prototype.run = function() {
  for (let i = this.particles.length-1; i >= 0; i--) {
    let p = this.particles[i];
    p.run();
    if (p.isDead()) {
      this.particles.splice(i, 1);
    }
  }
};

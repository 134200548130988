import React, {useState, useEffect} from 'react'
import Cookies from 'js-cookie'
import PANSL from './classes/PANSL'
import './App.css'
import episodeContent from './content/1-worms-of-a-feather'
import EpisodeContentProvider from './components/EpisodeContentProvider'
import Button from './components/Button'

// import Firebase from './classes/Firebase'
// const f = new Firebase()

const PageContainer = ({children}) => <div className='pageContainer'>{children}</div>

const personalityProfile = new PANSL(0, 0, 0, 0, 0)
personalityProfile.load()

class GameController extends React.Component {
    state = {
        isFetching: true,
    }

    async _fetch() {
        // await f.fetch()
        this.setState({isFetching: false})
    }

    componentDidMount() {
        this._fetch()
    }

    render() {
        const {isFetching,} = this.state

        return isFetching ? 'fetching...' : <EpisodeContentProvider content={episodeContent} personalityProfile={personalityProfile} />
    }
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    async function start() {
        // await f.startGame()
        setIsLoggedIn(true)
    }

    start()
  }, [setIsLoggedIn])

  return !isLoggedIn ? 'loading...' : (
    <div>
        <PageContainer>
            <GameController personalityProfile={personalityProfile} />
        </PageContainer>
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', position: 'fixed', maxWidth: '100%', left: 0, right: 0, bottom: 0, backgroundColor: 'black', zIndex: 100,}}>
            {/*personalityProfile.map(([letter, value]) => <div key={letter} style={{color: 'white'}}>{`${letter}: ${!value ? 0 : value.toFixed(2)}`}</div>)*/}
            <Button small title='Reset' onClick={() => {
                Cookies.remove('episodeOneSection')
                Cookies.remove('pansl')
                window.location.reload()
            }} />
        </div>
    </div>
  )
}

export default App

import React from 'react'
import uniqueId from 'lodash.uniqueid'
import debounce from 'lodash.debounce'
import TypeIt from 'typeit'
import keyboardJS from 'keyboardjs'
import './Terminal.css'

class Terminal extends React.Component {
    state = {
        receivedInput: false,
        shownUpToIndex: 0,
        typingDone: false,
    }

    resetIndex() {
        this.setState({shownUpToIndex: 0, typingDone: false,}, () => this._resetTyper())
    }

    componentDidMount() {
        this._resetTyper()
        keyboardJS.bind('space', this._handlePressContinue)
    }

    componentWillUnmount() {
        keyboardJS.unbind('space', this._handlePressContinue)
    }

    componentDidUpdate(prevProps) {
        prevProps.paragraphs !== this.props.paragraphs && this.typer.reset()
        if (prevProps.isPaused !== this.props.isPaused) {
            this.props.isPaused
                ? this.typer.freeze()
                : this.typer.unfreeze()
        }
    }

    _handlePressContinue = () => {
        if (this.state.shownUpToIndex === this.props.paragraphs.length) {
            if (this.props.waitForInput && !this.state.receivedInput) {
                return
            }
            this.setState({receivedInput: false})
            this.props.done && this.props.done()
        } else {
            this.typer.freeze()
            this.typer.reset()
            this.setState({
                shownUpToIndex: this.state.shownUpToIndex + 1,
                typingDone: false,
            }, this._resetTyper)
        }
    }

    _resetTyper = () => {
        if (!!this.props.paragraphs[this.state.shownUpToIndex]) {
            if (typeof this.props.paragraphs[this.state.shownUpToIndex] === 'string') {
                this.typer = new TypeIt('#typeit', {
                    speed: 0,
                    cursor: false,
                    strings: [this.props.paragraphs[this.state.shownUpToIndex].replace(/inlineVideo\|\|(.*)\n(.*)/, '<div><video style="width: 100%; object-fit: fill;" src="$1" autoplay loop></video>$2</div>')],
                    afterComplete: typer => {
                        this.setState({typingDone: true})
                    },
                    beforeStep: () => {
                        this.state.shownUpToIndex > 0 && window.innerWidth > 600
                            ? document.getElementById('scrollContainer').scrollTo(0, document.getElementById('heightContainer').scrollHeight)
                            : document.getElementById('scrollContainer').scrollBy(0, 50)
                    },
                    afterStep: debounce(() => {
                        if (this.state.shownUpToIndex === 0) {
                            document.getElementById('docTop').scrollIntoView()
                        }
                    }, 300, {'leading': true, 'trailing': false,}),
                }).go()
            } else { // it's a component
                this._handlePressContinue()
            }
        } else {
            this.setState({typingDone: true})
            this.props.allTextShown && this.props.allTextShown()
        }
    }

    render() {
        return <div className='Terminal__container'>
            {this.props.paragraphs.map((Para, i) => {
                Para = typeof Para === 'string'
                    ? Para.replace(/inlineVideo\|\|(.*)\n(.*)/, '<div><video style="width: 100%; object-fit: fill;" src="$1" autoplay loop></video>$2</div>')
                    : Para

                return i < this.state.shownUpToIndex
                    ? <div key={uniqueId() + i}>
                        {typeof Para === 'function'
                            ? <Para
                                isPaused={this.props.isPaused}
                                key={i + uniqueId()}
                                onDone={() => this.setState({receivedInput: true}, this._handlePressContinue)}
                            />
                            : <div
                                key={uniqueId()}
                                style={{marginBottom: '24px'}}
                                dangerouslySetInnerHTML={{__html: Para}}
                                onClick={() => this.typer.options({speed: 1})}
                            />}
                        </div>
                    : null
            })}

            <div id='typeit'></div>

            {!this.props.isPaused &&
                (
                    (!!this.props.done || this.state.shownUpToIndex < this.props.paragraphs.length) ||
                    (this.state.shownUpToIndex !== this.props.paragraphs.length)
                ) &&
                !(this.props.waitForInput && this.state.shownUpToIndex === this.props.paragraphs.length) && <button id='continue' className={this.state.typingDone ? 'blink' : ''} style={{
                appearance: 'none',
                background: 'none',
                border: 'none',
                fontFamily: 'rooney-web, monospace',
                fontWeight: '400',
                fontSize: '19px',
                position: 'fixed',
                bottom: '24px',
                left: 0,
                color: 'white',
                outline: 'none',
                paddingTop: '22px',
                marginBottom: '28px',
                width: '100%',
            }} onClick={this._handlePressContinue}>
                <span className='largeScreen'>continue (spacebar)</span>
                <span className='smallScreen'>continue</span>
            </button>}
        </div>
    }
}

export default Terminal

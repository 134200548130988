import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDc9y6G3dHmbM3xHvARMokw_q21Y-GYA8A',
  authDomain: 'worm-afcf4.firebaseapp.com',
  databaseURL: 'https://worm-afcf4.firebaseio.com',
  projectId: 'worm-afcf4',
  storageBucket: 'worm-afcf4.appspot.com',
  messagingSenderId: '598296449463',
  appId: '1:598296449463:web:b16f4a84785975d7635832',
  measurementId: 'G-SMFC902Z5D'
}

firebase.apps.length === 0 && firebase.initializeApp(firebaseConfig)

const analytics = firebase.analytics()

export default firebase
export { analytics, }

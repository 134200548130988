import Cookies from 'js-cookie'
import map from 'lodash.map'
import {trackPansl} from '../analytics'

const asParam = num => {
  return Math.min(Math.max(Math.round(num), 0), 10)
}

class PANSL {
  listeners = []
  _pansl = {}

  constructor(p=0, a=0, n=0, s=0, l=0) {
    this._pansl.p = p // psych
    this._pansl.a = a // abstraction
    this._pansl.n = n // noise
    this._pansl.s = s // saturation
    this._pansl.l = l // literality

    this.balance = 0

    this.powers = {
        a: 5,
        v: 3,
        r: 2,
        p: 1,
    }
  }

  serialize() {
    return [
        this._pansl.p,
        this._pansl.a,
        this._pansl.n,
        this._pansl.s,
        this._pansl.l,
        this.powers.a,
        this.powers.r,
        this.powers.v,
        this.powers.p,
        this.balance,
    ]
  }

  map(iterator) {
    return ['p', 'a', 'n', 's', 'l', 'pa', 'pr', 'pv', 'pp', 'bal'].map(letter => {
      if (letter.length === 1) {
        return iterator([letter, this._pansl[letter]])
      } else if (letter.length === 2) {
        return iterator([letter, this.powers[letter[1]]])
      } else {
        return iterator([letter, this.balance])
      }
    })
  }

  deserialize([p=0, a=0, n=0, s=0, l=0, pa=0, pr=0, pv=0, pp=0, balance=0,]) {
    this._pansl.p = p
    this._pansl.a = a
    this._pansl.n = n
    this._pansl.s = s
    this._pansl.l = l
    this.powers.a = pa
    this.powers.r = pr
    this.powers.v = pv
    this.powers.p = pp
    this.balance = balance

    this.trigger()
  }

  savePersonalityProfile() {
    Cookies.set('pansl', JSON.stringify(this.serialize()))
  }

  load() {
    Cookies.get('pansl') && this.deserialize(JSON.parse(Cookies.get('pansl')))
  }

  trigger(eventType) {
    if (this.serialize() !== this._lastChange) {
        this.savePersonalityProfile()
        this.listeners.forEach(listener => listener(this, eventType))
        this._lastChange = this.serialize()
    }

  }

  addListener(cb) {
    this.listeners.push(cb)
    return () => this.listeners.splice(this.listeners.indexOf(cb), 1)
  }

  adjustPersonalityProfile(params) {
    Object.keys(params).forEach(paramKey => {
        this._pansl[paramKey] += params[paramKey]
    });

    ['a', 'v', 'r', 'p'].forEach(p => {
      const charge = this._costOfChangeTo(p) + 1
        this.powers[p] += Math.max(Math.abs(this.balance * Math.random()), charge)
    })

    trackPansl(this.getVariableContent())

    this.balance += 1

    this.trigger('earned')

    return this
  }

  powerValue(p) {
    const panslValues = map(this._pansl, n => n)
    panslValues.sort()

    return Math.abs(
      this._pansl[p === 'r' ? 'n' : 's'] / Math.max(1, panslValues[panslValues.length - 1])
    )
  }

  powerValueReadable(p) {
    return this.powers[p].toFixed(2)
  }

  hasEnoughPowerForDecode() {
    return ['a', 'v', 'r', 'p'].map(p => this.powers[p] - this._costOfChangeTo(p, true)).filter(v => v <= 0).length === 0
  }

  _costOfChangeTo = (p, noRandom) => Math.abs((Math.min(10, this.powers[p] / Math.max(10, (this.powerValue(p) + this.balance)))) + (noRandom ? 1 : Math.random()) * this.balance)

  _applyCostTo = p => {
    this.powers[p] -= this._costOfChangeTo(p)
  }

  applyCost() {
    ['a', 'v', 'r', 'p'].forEach(this._applyCostTo)

    this.balance -= 1

    setTimeout(() => this.trigger('spent'))

    return this
  }

  determineVisualizationParam(letter) {
    switch (letter) {
        case 'a':
            return asParam(this.powers['a'])
        case 'v':
            return asParam(this.powers['v'])
        case 'r':
            return asParam(this.powers['r'])
        case 'p':
            return asParam(this.powers['p'])
        default:
            throw new Error(letter + ' is not a visualization param')
    }
  }

  getVariableContent() {
    return {
      dangerLandmark: this.determineDangerLandmark(),
      body: this.determineBody(),
      head: this.determineHead(),
      tSContact: this.determineTSContact(),
      tSAssistant: this.determineTSAssistant(),
    }
  }

  determineDangerLandmark() {
    // ['hole', 'geyser', 'sinkhole', 'ravine', 'volcano', 'void']

    if (this._pansl.p > 30 && this._pansl.l < 0) {
      return 'void'
    } else if (this._pansl.a > 30 && this._pansl.n > 10 && this._pansl.s > 20) {
      return 'volcano'
    } else if (this._pansl.l > 30) {
      return 'hole'
    } else if (this._pansl.l < -10 && this._pansl.n > 10) {
      return 'geyser'
    } else if (this._pansl.l < 0 && this._pansl.p > 20) {
      return 'sinkhole'
    } else {
      return 'ravine'
    }
  }

  determineBody() {
    // ['worm', 'spider', 'bee', 'moth', 'centipede', 'silverfish']
    if (this._pansl.p > 10 && this._pansl.a < 0 && this._pansl.l > 20) {
      return 'spider'
    } else if (this._pansl.p > 20 && this._pansl.a > 20 && this._pansl.n > 10) {
      return 'bee'
    } else if (this._pansl.n < 10 && this._pansl.l > 10) {
      return 'moth'
    } else if (this._pansl.n > 20 && this._pansl.l > 30) {
      return 'centipede'
    } else if (this._pansl.s > 20 && this._pansl.p > 30) {
      return 'silverfish'
    } else {
      return 'worm'
    }
  }

  determineHead() {
    // ['apartment', 'house', 'office', 'clubhouse']

    if (this._pansl.p > 20 && this._pansl.a > 20 && this._pansl.n > 10) {
      return 'funhouse'
    } else if (this._pansl.n < 0 && this._pansl.l < 10 && this._pansl.a < 10) {
      return 'house'
    } else if (this._pansl.n < 10 && this._pansl.l > 10) {
      return 'office'
    } else if (this._pansl.n > 20 && this._pansl.l < 20) {
      return 'clubhouse'
    } else {
      return 'apartment'
    }
  }

  determineTSContact() {
    // const TSContacts = ['cadmium', 'bromium', 'rongium', 'irons', 'rockfort']

    if (this._pansl.p > 20) {
      return 'Rongium'
    } else if (this._pansl.a > 20) {
      return 'Bromium'
    } else if (this._pansl.l > 20) {
      return 'Rockfort'
    } else if (this._pansl.n > 20) {
      return 'Irons'
    } else {
      return 'Cadmium'
    }
  }

  determineTSAssistant() {
    // const TSassistants = ['receptionist', 'assistant']

    if (this._pansl.l > 20) {
      return 'assistant'
    } else {
      return 'receptionist'
    }
  }
}

export default PANSL
